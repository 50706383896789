/* padding */
.p-1px {
  padding: 1px !important;
}
.p-2px {
  padding: 2px !important;
}
.p-4px {
  padding: 4px !important;
}
.p-6px {
  padding: 6px !important;
}
.p-8px {
  padding: 8px !important;
}
.p-10px {
  padding: 10px !important;
}
.p-11px {
  padding: 11px !important;
}
.p-12px {
  padding: 12px !important;
}
.p-14px {
  padding: 14px !important;
}
.p-16px {
  padding: 16px !important;
}
.p-18px {
  padding: 18px !important;
}
.p-20px {
  padding: 20px !important;
}
.p-22px {
  padding: 22px !important;
}
.p-24px {
  padding: 24px !important;
}
.p-28px {
  padding: 28px !important;
}
.p-32px {
  padding: 32px !important;
}
.p-40px {
  padding: 40px !important;
}
.p-48px {
  padding: 48px !important;
}
.p-60px {
  padding: 60px !important;
}
.p-80px {
  padding: 80px !important;
}
.p-120px {
  padding: 120px !important;
}
.p-150px {
  padding: 150px !important;
}

.pb-1px {
  padding-bottom: 1px !important;
}
.pb-2px {
  padding-bottom: 2px !important;
}
.pb-4px {
  padding-bottom: 4px !important;
}
.pb-6px {
  padding-bottom: 6px !important;
}
.pb-8px {
  padding-bottom: 8px !important;
}
.pb-10px {
  padding-bottom: 10px !important;
}
.pb-12px {
  padding-bottom: 12px !important;
}
.pb-14px {
  padding-bottom: 14px !important;
}
.pb-16px {
  padding-bottom: 16px !important;
}
.pb-18px {
  padding-bottom: 18px !important;
}
.pb-20px {
  padding-bottom: 20px !important;
}
.pb-22px {
  padding-bottom: 22px !important;
}
.pb-24px {
  padding-bottom: 24px !important;
}
.pb-28px {
  padding-bottom: 28px !important;
}
.pb-32px {
  padding-bottom: 32px !important;
}
.pb-40px {
  padding-bottom: 40px !important;
}
.pb-48px {
  padding-bottom: 48px !important;
}
.pb-60px {
  padding-bottom: 60px !important;
}
.pb-80px {
  padding-bottom: 80px !important;
}
.pb-120px {
  padding-bottom: 120px !important;
}
.pb-150px {
  padding-bottom: 150px !important;
}

.pl-1px {
  padding-left: 1px !important;
}
.pl-2px {
  padding-left: 2px !important;
}
.pl-4px {
  padding-left: 4px !important;
}
.pl-6px {
  padding-left: 6px !important;
}
.pl-8px {
  padding-left: 8px !important;
}
.pl-10px {
  padding-left: 10px !important;
}
.pl-12px {
  padding-left: 12px !important;
}
.pl-14px {
  padding-left: 14px !important;
}
.pl-16px {
  padding-left: 16px !important;
}
.pl-18px {
  padding-left: 18px !important;
}
.pl-20px {
  padding-left: 20px !important;
}
.pl-22px {
  padding-left: 22px !important;
}
.pl-24px {
  padding-left: 24px !important;
}
.pl-28px {
  padding-left: 28px !important;
}
.pl-32px {
  padding-left: 32px !important;
}
.pl-40px {
  padding-left: 40px !important;
}
.pl-48px {
  padding-left: 48px !important;
}
.pl-60px {
  padding-left: 60px !important;
}
.pl-80px {
  padding-left: 80px !important;
}
.pl-120px {
  padding-left: 120px !important;
}
.pl-150px {
  padding-left: 150px !important;
}

.pr-1px {
  padding-right: 1px !important;
}
.pr-2px {
  padding-right: 2px !important;
}
.pr-4px {
  padding-right: 4px !important;
}
.pr-6px {
  padding-right: 6px !important;
}
.pr-8px {
  padding-right: 8px !important;
}
.pr-10px {
  padding-right: 10px !important;
}
.pr-12px {
  padding-right: 12px !important;
}
.pr-14px {
  padding-right: 14px !important;
}
.pr-16px {
  padding-right: 16px !important;
}
.pr-18px {
  padding-right: 18px !important;
}
.pr-20px {
  padding-right: 20px !important;
}
.pr-22px {
  padding-right: 22px !important;
}
.pr-24px {
  padding-right: 24px !important;
}
.pr-28px {
  padding-right: 28px !important;
}
.pr-30px {
  padding-right: 30px !important;
}
.pr-32px {
  padding-right: 32px !important;
}
.pr-38px {
  padding-right: 38px !important;
}
.pr-40px {
  padding-right: 40px !important;
}
.pr-48px {
  padding-right: 48px !important;
}
.pr-60px {
  padding-right: 60px !important;
}
.pr-80px {
  padding-right: 80px !important;
}
.pr-108px {
  padding-right: 108px !important;
}
.pr-120px {
  padding-right: 120px !important;
}
.pr-150px {
  padding-right: 150px !important;
}

.pt-1px {
  padding-top: 1px !important;
}
.pt-2px {
  padding-top: 2px !important;
}
.pt-4px {
  padding-top: 4px !important;
}
.pt-6px {
  padding-top: 6px !important;
}
.pt-8px {
  padding-top: 8px !important;
}
.pt-10px {
  padding-top: 10px !important;
}
.pt-12px {
  padding-top: 12px !important;
}
.pt-14px {
  padding-top: 14px !important;
}
.pt-16px {
  padding-top: 16px !important;
}
.pt-18px {
  padding-top: 18px !important;
}
.pt-20px {
  padding-top: 20px !important;
}
.pt-22px {
  padding-top: 22px !important;
}
.pt-24px {
  padding-top: 24px !important;
}
.pt-28px {
  padding-top: 28px !important;
}
.pt-32px {
  padding-top: 32px !important;
}
.pt-40px {
  padding-top: 40px !important;
}
.pt-48px {
  padding-top: 48px !important;
}
.pt-60px {
  padding-top: 60px !important;
}
.pt-80px {
  padding-top: 80px !important;
}
.pt-120px {
  padding-top: 120px !important;
}
.pt-150px {
  padding-top: 150px !important;
}
.pt-184px {
  padding-top: 184px !important;
}

.px-1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.px-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.px-4px {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.px-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.px-8px {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.px-14px {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.px-16px {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.px-18px {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-22px {
  padding-left: 22px !important;
  padding-right: 22px !important;
}
.px-24px {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.px-28px {
  padding-left: 28px !important;
  padding-right: 28px !important;
}
.px-32px {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.px-40px {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.px-45px {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.px-48px {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.px-56px {
  padding-left: 56px !important;
  padding-right: 56px !important;
}
.px-60px {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.px-80px {
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.px-120px {
  padding-left: 120px !important;
  padding-right: 120px !important;
}
.px-150px {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.py-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.py-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.py-14px {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.py-16px {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.py-18px {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-22px {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}
.py-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.py-28px {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.py-32px {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.py-40px {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-48px {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.py-60px {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.py-80px {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.py-120px {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}
.py-150px {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

/* margin */
.m-1px {
  margin: 1px !important;
}
.m-2px {
  margin: 2px !important;
}
.m-4px {
  margin: 4px !important;
}
.m-6px {
  margin: 6px !important;
}
.m-8px {
  margin: 8px !important;
}
.m-10px {
  margin: 10px !important;
}
.m-12px {
  margin: 12px !important;
}
.m-14px {
  margin: 14px !important;
}
.m-16px {
  margin: 16px !important;
}
.m-18px {
  margin: 18px !important;
}
.m-20px {
  margin: 20px !important;
}
.m-22px {
  margin: 22px !important;
}
.m-24px {
  margin: 24px !important;
}
.m-28px {
  margin: 28px !important;
}
.m-32px {
  margin: 32px !important;
}
.m-40px {
  margin: 40px !important;
}
.m-48px {
  margin: 48px !important;
}
.m-60px {
  margin: 60px !important;
}
.m-80px {
  margin: 80px !important;
}
.m-120px {
  margin: 120px !important;
}
.m-150px {
  margin: 150px !important;
}

.mt-1px {
  margin-top: 1px !important;
}
.mt-2px {
  margin-top: 2px !important;
}
.mt-4px {
  margin-top: 4px !important;
}
.mt-6px {
  margin-top: 6px !important;
}
.mt-8px {
  margin-top: 8px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-12px {
  margin-top: 12px !important;
}
.mt-14px {
  margin-top: 14px !important;
}
.mt-16px {
  margin-top: 16px !important;
}
.mt-18px {
  margin-top: 18px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-22px {
  margin-top: 22px !important;
}
.mt-24px {
  margin-top: 24px !important;
}
.mt-28px {
  margin-top: 28px !important;
}
.mt-32px {
  margin-top: 32px !important;
}
.mt-40px {
  margin-top: 40px !important;
}
.mt-48px {
  margin-top: 48px !important;
}
.mt-50px {
  margin-top: 50px !important;
}
.mt-60px {
  margin-top: 60px !important;
}
.mt-80px {
  margin-top: 80px !important;
}
.mt-120px {
  margin-top: 120px !important;
}
.mt-150px {
  margin-top: 150px !important;
}

.mb-1px {
  margin-bottom: 1px !important;
}
.mb-2px {
  margin-bottom: 2px !important;
}
.mb-4px {
  margin-bottom: 4px !important;
}
.mb-6px {
  margin-bottom: 6px !important;
}
.mb-8px {
  margin-bottom: 8px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mb-12px {
  margin-bottom: 12px !important;
}
.mb-14px {
  margin-bottom: 14px !important;
}
.mb-16px {
  margin-bottom: 16px !important;
}
.mb-18px {
  margin-bottom: 18px !important;
}
.mb-20px {
  margin-bottom: 20px !important;
}
.mb-22px {
  margin-bottom: 22px !important;
}
.mb-24px {
  margin-bottom: 24px !important;
}
.mb-28px {
  margin-bottom: 28px !important;
}
.mb-32px {
  margin-bottom: 32px !important;
}
.mb-36px {
  margin-bottom: 36px !important;
}
.mb-40px {
  margin-bottom: 40px !important;
}
.mb-48px {
  margin-bottom: 48px !important;
}
.mb-60px {
  margin-bottom: 60px !important;
}
.mb-80px {
  margin-bottom: 80px !important;
}
.mb-120px {
  margin-bottom: 120px !important;
}
.mb-150px {
  margin-bottom: 150px !important;
}

.ml-1px {
  margin-left: 1px !important;
}
.ml-2px {
  margin-left: 2px !important;
}
.ml-4px {
  margin-left: 4px !important;
}
.ml-6px {
  margin-left: 6px !important;
}
.ml-8px {
  margin-left: 8px !important;
}
.ml-10px {
  margin-left: 10px !important;
}
.ml-12px {
  margin-left: 12px !important;
}
.ml-14px {
  margin-left: 14px !important;
}
.ml-16px {
  margin-left: 16px !important;
}
.ml-18px {
  margin-left: 18px !important;
}
.ml-20px {
  margin-left: 20px !important;
}
.ml-22px {
  margin-left: 22px !important;
}
.ml-24px {
  margin-left: 24px !important;
}
.ml-28px {
  margin-left: 28px !important;
}
.ml-32px {
  margin-left: 32px !important;
}
.ml-40px {
  margin-left: 40px !important;
}
.ml-48px {
  margin-left: 48px !important;
}
.ml-60px {
  margin-left: 60px !important;
}
.ml-80px {
  margin-left: 80px !important;
}
.ml-120px {
  margin-left: 120px !important;
}
.ml-150px {
  margin-left: 150px !important;
}

.mr-1px {
  margin-right: 1px !important;
}
.mr-2px {
  margin-right: 2px !important;
}
.mr-4px {
  margin-right: 4px !important;
}
.mr-6px {
  margin-right: 6px !important;
}
.mr-8px {
  margin-right: 8px !important;
}
.mr-10px {
  margin-right: 10px !important;
}
.mr-12px {
  margin-right: 12px !important;
}
.mr-14px {
  margin-right: 14px !important;
}
.mr-16px {
  margin-right: 16px !important;
}
.mr-18px {
  margin-right: 18px !important;
}
.mr-20px {
  margin-right: 20px !important;
}
.mr-22px {
  margin-right: 22px !important;
}
.mr-24px {
  margin-right: 24px !important;
}
.mr-28px {
  margin-right: 28px !important;
}
.mr-32px {
  margin-right: 32px !important;
}
.mr-40px {
  margin-right: 40px !important;
}
.mr-48px {
  margin-right: 48px !important;
}
.mr-60px {
  margin-right: 60px !important;
}
.mr-80px {
  margin-right: 80px !important;
}
.mr-120px {
  margin-right: 120px !important;
}
.mr-150px {
  margin-right: 150px !important;
}

.mx-1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}
.mx-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.mx-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mx-6px {
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.mx-8px {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-12px {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.mx-14px {
  margin-left: 14px !important;
  margin-right: 14px !important;
}
.mx-16px {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mx-18px {
  margin-left: 18px !important;
  margin-right: 18px !important;
}
.mx-20px {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-22px {
  margin-left: 22px !important;
  margin-right: 22px !important;
}
.mx-24px {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.mx-28px {
  margin-left: 28px !important;
  margin-right: 28px !important;
}
.mx-32px {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.mx-40px {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.mx-48px {
  margin-left: 48px !important;
  margin-right: 48px !important;
}
.mx-60px {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
.mx-80px {
  margin-left: 80px !important;
  margin-right: 80px !important;
}
.mx-120px {
  margin-left: 120px !important;
  margin-right: 120px !important;
}
.mx-150px {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.my-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.my-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.my-6px {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-12px {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.my-14px {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}
.my-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.my-18px {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}
.my-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.my-22px {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}
.my-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.my-28px {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}
.my-32px {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.my-40px {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.my-48px {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.my-60px {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.my-80px {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.my-120px {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}
.my-150px {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

/* negative margin */
.-m-1px {
  margin: -1px !important;
}
.-m-2px {
  margin: -2px !important;
}
.-m-4px {
  margin: -4px !important;
}
.-m-6px {
  margin: -6px !important;
}
.-m-8px {
  margin: -8px !important;
}
.-m-10px {
  margin: -10px !important;
}
.-m-12px {
  margin: -12px !important;
}
.-m-14px {
  margin: -14px !important;
}
.-m-16px {
  margin: -16px !important;
}
.-m-18px {
  margin: -18px !important;
}
.-m-20px {
  margin: -20px !important;
}
.-m-22px {
  margin: -22px !important;
}
.-m-24px {
  margin: -24px !important;
}
.-m-28px {
  margin: -28px !important;
}
.-m-32px {
  margin: -32px !important;
}
.-m-40px {
  margin: -40px !important;
}
.-m-48px {
  margin: -48px !important;
}
.-m-60px {
  margin: -60px !important;
}
.-m-80px {
  margin: -80px !important;
}
.-m-120px {
  margin: -120px !important;
}
.-m-150px {
  margin: -150px !important;
}

.-mb-1px {
  margin-bottom: -1px !important;
}
.-mb-2px {
  margin-bottom: -2px !important;
}
.-mb-4px {
  margin-bottom: -4px !important;
}
.-mb-6px {
  margin-bottom: -6px !important;
}
.-mb-8px {
  margin-bottom: -8px !important;
}
.-mb-10px {
  margin-bottom: -10px !important;
}
.-mb-12px {
  margin-bottom: -12px !important;
}
.-mb-14px {
  margin-bottom: -14px !important;
}
.-mb-16px {
  margin-bottom: -16px !important;
}
.-mb-18px {
  margin-bottom: -18px !important;
}
.-mb-20px {
  margin-bottom: -20px !important;
}
.-mb-22px {
  margin-bottom: -22px !important;
}
.-mb-24px {
  margin-bottom: -24px !important;
}
.-mb-28px {
  margin-bottom: -28px !important;
}
.-mb-32px {
  margin-bottom: -32px !important;
}
.-mb-40px {
  margin-bottom: -40px !important;
}
.-mb-48px {
  margin-bottom: -48px !important;
}
.-mb-60px {
  margin-bottom: -60px !important;
}
.-mb-80px {
  margin-bottom: -80px !important;
}
.-mb-120px {
  margin-bottom: -120px !important;
}
.-mb-150px {
  margin-bottom: -150px !important;
}

.-ml-1px {
  margin-left: -1px !important;
}
.-ml-2px {
  margin-left: -2px !important;
}
.-ml-4px {
  margin-left: -4px !important;
}
.-ml-6px {
  margin-left: -6px !important;
}
.-ml-8px {
  margin-left: -8px !important;
}
.-ml-10px {
  margin-left: -10px !important;
}
.-ml-12px {
  margin-left: -12px !important;
}
.-ml-14px {
  margin-left: -14px !important;
}
.-ml-16px {
  margin-left: -16px !important;
}
.-ml-18px {
  margin-left: -18px !important;
}
.-ml-20px {
  margin-left: -20px !important;
}
.-ml-22px {
  margin-left: -22px !important;
}
.-ml-24px {
  margin-left: -24px !important;
}
.-ml-28px {
  margin-left: -28px !important;
}
.-ml-32px {
  margin-left: -32px !important;
}
.-ml-40px {
  margin-left: -40px !important;
}
.-ml-48px {
  margin-left: -48px !important;
}
.-ml-60px {
  margin-left: -60px !important;
}
.-ml-80px {
  margin-left: -80px !important;
}
.-ml-120px {
  margin-left: -120px !important;
}
.-ml-150px {
  margin-left: -150px !important;
}

.-mr-1px {
  margin-right: -1px !important;
}
.-mr-2px {
  margin-right: -2px !important;
}
.-mr-4px {
  margin-right: -4px !important;
}
.-mr-6px {
  margin-right: -6px !important;
}
.-mr-8px {
  margin-right: -8px !important;
}
.-mr-10px {
  margin-right: -10px !important;
}
.-mr-12px {
  margin-right: -12px !important;
}
.-mr-14px {
  margin-right: -14px !important;
}
.-mr-16px {
  margin-right: -16px !important;
}
.-mr-18px {
  margin-right: -18px !important;
}
.-mr-20px {
  margin-right: -20px !important;
}
.-mr-22px {
  margin-right: -22px !important;
}
.-mr-24px {
  margin-right: -24px !important;
}
.-mr-28px {
  margin-right: -28px !important;
}
.-mr-32px {
  margin-right: -32px !important;
}
.-mr-40px {
  margin-right: -40px !important;
}
.-mr-48px {
  margin-right: -48px !important;
}
.-mr-60px {
  margin-right: -60px !important;
}
.-mr-80px {
  margin-right: -80px !important;
}
.-mr-120px {
  margin-right: -120px !important;
}
.-mr-150px {
  margin-right: -150px !important;
}

.-mt-1px {
  margin-top: -1px !important;
}
.-mt-2px {
  margin-top: -2px !important;
}
.-mt-4px {
  margin-top: -4px !important;
}
.-mt-6px {
  margin-top: -6px !important;
}
.-mt-8px {
  margin-top: -8px !important;
}
.-mt-10px {
  margin-top: -10px !important;
}
.-mt-12px {
  margin-top: -12px !important;
}
.-mt-14px {
  margin-top: -14px !important;
}
.-mt-16px {
  margin-top: -16px !important;
}
.-mt-18px {
  margin-top: -18px !important;
}
.-mt-20px {
  margin-top: -20px !important;
}
.-mt-22px {
  margin-top: -22px !important;
}
.-mt-24px {
  margin-top: -24px !important;
}
.-mt-28px {
  margin-top: -28px !important;
}
.-mt-32px {
  margin-top: -32px !important;
}
.-mt-40px {
  margin-top: -40px !important;
}
.-mt-48px {
  margin-top: -48px !important;
}
.-mt-60px {
  margin-top: -60px !important;
}
.-mt-80px {
  margin-top: -80px !important;
}
.-mt-120px {
  margin-top: -120px !important;
}
.-mt-150px {
  margin-top: -150px !important;
}

.-mx-1px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}
.-mx-2px {
  margin-left: -2px !important;
  margin-right: -2px !important;
}
.-mx-4px {
  margin-left: -4px !important;
  margin-right: -4px !important;
}
.-mx-6px {
  margin-left: -6px !important;
  margin-right: -6px !important;
}
.-mx-8px {
  margin-left: -8px !important;
  margin-right: -8px !important;
}
.-mx-10px {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.-mx-12px {
  margin-left: -12px !important;
  margin-right: -12px !important;
}
.-mx-14px {
  margin-left: -14px !important;
  margin-right: -14px !important;
}
.-mx-16px {
  margin-left: -16px !important;
  margin-right: -16px !important;
}
.-mx-18px {
  margin-left: -18px !important;
  margin-right: -18px !important;
}
.-mx-20px {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.-mx-22px {
  margin-left: -22px !important;
  margin-right: -22px !important;
}
.-mx-24px {
  margin-left: -24px !important;
  margin-right: -24px !important;
}
.-mx-28px {
  margin-left: -28px !important;
  margin-right: -28px !important;
}
.-mx-32px {
  margin-left: -32px !important;
  margin-right: -32px !important;
}
.-mx-40px {
  margin-left: -40px !important;
  margin-right: -40px !important;
}
.-mx-48px {
  margin-left: -48px !important;
  margin-right: -48px !important;
}
.-mx-60px {
  margin-left: -60px !important;
  margin-right: -60px !important;
}
.-mx-80px {
  margin-left: -80px !important;
  margin-right: -80px !important;
}
.-mx-120px {
  margin-left: -120px !important;
  margin-right: -120px !important;
}
.-mx-150px {
  margin-left: -150px !important;
  margin-right: -150px !important;
}

.-my-1px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}
.-my-2px {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}
.-my-4px {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}
.-my-6px {
  margin-top: -6px !important;
  margin-bottom: -6px !important;
}
.-my-8px {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}
.-my-10px {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}
.-my-12px {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}
.-my-14px {
  margin-top: -14px !important;
  margin-bottom: -14px !important;
}
.-my-16px {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}
.-my-18px {
  margin-top: -18px !important;
  margin-bottom: -18px !important;
}
.-my-20px {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}
.-my-22px {
  margin-top: -22px !important;
  margin-bottom: -22px !important;
}
.-my-24px {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}
.-my-28px {
  margin-top: -28px !important;
  margin-bottom: -28px !important;
}
.-my-32px {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}
.-my-40px {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}
.-my-48px {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}
.-my-60px {
  margin-top: -60px !important;
  margin-bottom: -60px !important;
}
.-my-80px {
  margin-top: -80px !important;
  margin-bottom: -80px !important;
}
.-my-120px {
  margin-top: -120px !important;
  margin-bottom: -120px !important;
}
.-my-150px {
  margin-top: -150px !important;
  margin-bottom: -150px !important;
}

/* flex gap */
.gap-1px {
  gap: 1px !important;
}
.gap-2px {
  gap: 2px !important;
}
.gap-3px {
  gap: 3px !important;
}
.gap-4px {
  gap: 4px !important;
}
.gap-5px {
  gap: 5px !important;
}
.gap-6px {
  gap: 6px !important;
}
.gap-8px {
  gap: 8px !important;
}
.gap-10px {
  gap: 8px !important;
}
.gap-12px {
  gap: 12px !important;
}
.gap-14px {
  gap: 14px !important;
}
.gap-16px {
  gap: 16px !important;
}
.gap-20px {
  gap: 20px !important;
}
.gap-24px {
  gap: 24px !important;
}
.gap-28px {
  gap: 28px !important;
}
.gap-32px {
  gap: 32px !important;
}
.gap-40px {
  gap: 40px !important;
}
.gap-48px {
  gap: 48px !important;
}
.gap-60px {
  gap: 60px !important;
}
.gap-80px {
  gap: 80px !important;
}
.gap-120px {
  gap: 120px !important;
}

/* width */
.w-0 {
  width: 0 !important;
}
.w-1px {
  width: 1px !important;
}
.w-2px {
  width: 2px !important;
}
.w-4px {
  width: 4px !important;
}
.w-6px {
  width: 6px !important;
}
.w-8px {
  width: 8px !important;
}
.w-10px {
  width: 10px !important;
}
.w-12px {
  width: 12px !important;
}
.w-14px {
  width: 14px !important;
}
.w-16px {
  width: 16px !important;
}
.w-18px {
  width: 18px !important;
}
.w-20px {
  width: 20px !important;
}
.w-24px {
  width: 24px !important;
}
.w-28px {
  width: 28px !important;
}
.w-32px {
  width: 32px !important;
}
.w-34px {
  width: 34px !important;
}
.w-40px {
  width: 40px !important;
}
.w-45px {
  width: 45px !important;
}
.w-48px {
  width: 48px !important;
}
.w-56px {
  width: 56px !important;
}
.w-60px {
  width: 60px !important;
}
.w-70px {
  width: 70px !important;
}
.w-75px {
  width: 75px !important;
}
.w-80px {
  width: 80px !important;
}
.w-90px {
  width: 90px !important;
}
.w-120px {
  width: 120px !important;
}
.w-130px {
  width: 130px !important;
}
.w-144px {
  width: 144px !important;
}
.w-150px {
  width: 150px !important;
}
.w-164px {
  width: 164px !important;
}
.w-189px {
  width: 189px !important;
}
.w-192px {
  width: 192px !important;
}
.w-200px {
  width: 200px !important;
}
.w-220px {
  width: 220px !important;
}
.w-240px {
  width: 240px !important;
}
.w-264px {
  width: 264px !important;
}
.w-280px {
  width: 280px !important;
}
.w-300px {
  width: 300px !important;
}
.w-320px {
  width: 320px !important;
}
.w-335px {
  width: 335px !important;
}
.w-360px {
  width: 360px !important;
}
.w-400px {
  width: 400px !important;
}
.w-412px {
  width: 412px !important;
}
.w-440px {
  width: 440px !important;
}
.w-460px {
  width: 460px !important;
}
.w-500px {
  width: 500px !important;
}
.w-560px {
  width: 560px !important;
}
.w-580px {
  width: 580px !important;
}
.w-680px {
  width: 680px !important;
}
.w-700px {
  width: 700px !important;
}
.w-820px {
  width: 820px !important;
}
.w-840px {
  width: 840px !important;
}
.w-900px {
  width: 900px !important;
}

/* min width */
.min-w-20px {
  min-width: 20px !important;
}
.min-w-40px {
  min-width: 40px !important;
}
.min-w-60px {
  min-width: 60px !important;
}
.min-w-90px {
  min-width: 90px !important;
}
.min-w-120px {
  min-width: 120px !important;
}
.min-w-150px {
  min-width: 150px !important;
}
.min-w-189px {
  min-width: 189px !important;
}
.min-w-200px {
  min-width: 200px !important;
}
.min-w-240px {
  min-width: 240px !important;
}
.min-w-360px {
  min-width: 360px !important;
}
.min-w-480px {
  min-width: 480px !important;
}

/* max width */
.max-w-20px {
  max-width: 20px !important;
}
.max-w-40px {
  max-width: 40px !important;
}
.max-w-60px {
  max-width: 60px !important;
}
.max-w-80px {
  max-width: 80px !important;
}
.max-w-90px {
  max-width: 90px !important;
}
.max-w-150px {
  max-width: 150px !important;
}
.max-w-200px {
  max-width: 200px !important;
}
.max-w-240px {
  max-width: 240px !important;
}
.max-w-360px {
  max-width: 360px !important;
}
.max-w-480px {
  max-width: 480px !important;
}
.max-w-900px {
  max-width: 900px !important;
}

/* height */
.h-0 {
  height: 0 !important;
}
.h-1px {
  height: 1px !important;
}
.h-2px {
  height: 2px !important;
}
.h-4px {
  height: 4px !important;
}
.h-6px {
  height: 6px !important;
}
.h-8px {
  height: 8px !important;
}
.h-10px {
  height: 10px !important;
}
.h-12px {
  height: 12px !important;
}
.h-14px {
  height: 14px !important;
}
.h-16px {
  height: 16px !important;
}
.h-18px {
  height: 18px !important;
}
.h-20px {
  height: 20px !important;
}
.h-24px {
  height: 24px !important;
}
.h-26px {
  height: 26px !important;
}
.h-28px {
  height: 28px !important;
}
.h-30px {
  height: 30px !important;
}
.h-32px {
  height: 32px !important;
}
.h-34px {
  height: 34px !important;
}
.h-38px {
  height: 38px !important;
}
.h-40px {
  height: 40px !important;
}
.h-42px {
  height: 42px !important;
}
.h-48px {
  height: 48px !important;
}
.h-56px {
  height: 56px !important;
}
.h-60px {
  height: 60px !important;
}
.h-70px {
  height: 70px !important;
}
.h-80px {
  height: 80px !important;
}
.h-88px {
  height: 88px !important;
}
.h-90px {
  height: 90px !important;
}
.h-96px {
  height: 96px !important;
}
.h-120px {
  height: 120px !important;
}
.h-140px {
  height: 140px !important;
}
.h-150px {
  height: 150px !important;
}
.h-170px {
  height: 170px !important;
}
.h-200px {
  height: 200px !important;
}
.h-240px {
  height: 240px !important;
}
.h-340px {
  height: 340px !important;
}
.h-360px {
  height: 360px !important;
}
.h-440px {
  height: 440px !important;
}
.h-460px {
  height: 460px !important;
}
.h-520px {
  height: 520px !important;
}
.h-680px {
  height: 680px !important;
}
.h-640px {
  height: 640px !important;
}

/* max height */
.max-h-10px {
  max-height: 10px !important;
}
.max-h-20px {
  max-height: 20px !important;
}
.max-h-40px {
  max-height: 40px !important;
}
.max-h-60px {
  max-height: 60px !important;
}
.max-h-80px {
  max-height: 80px !important;
}
.max-h-90px {
  max-height: 90px !important;
}
.max-h-120px {
  max-height: 120px !important;
}
.max-h-200px {
  max-height: 200px !important;
}

/* .border-radius */
.border-radius-none {
  border-radius: 0 !important;
}
.border-radius-2px {
  border-radius: 2px !important;
}
.border-radius-4px {
  border-radius: 4px !important;
}
.border-radius-6px {
  border-radius: 6px !important;
}
.border-radius-8px {
  border-radius: 8px !important;
}
.border-radius-10px {
  border-radius: 10px !important;
}
.border-radius-12px {
  border-radius: 12px !important;
}
.border-radius-14px {
  border-radius: 14px !important;
}
.border-radius-16px {
  border-radius: 16px !important;
}
.border-radius-20px {
  border-radius: 20px !important;
}

/* translate */
.translate-up-2px {
  transform: translateY(-2px) !important;
}
.translate-up-4px {
  transform: translateY(-4px) !important;
}
.translate-up-6px {
  transform: translateY(-6px) !important;
}
.translate-up-8px {
  transform: translateY(-8px) !important;
}
.translate-up-10px {
  transform: translateY(-10px) !important;
}
.translate-down-2px {
  transform: translateY(2px) !important;
}
.translate-down-4px {
  transform: translateY(4px) !important;
}
.translate-down-6px {
  transform: translateY(6px) !important;
}
.translate-down-8px {
  transform: translateY(8px) !important;
}
.translate-down-10px {
  transform: translateY(10px) !important;
}
.translate-left-2px {
  transform: translateX(-2px) !important;
}
.translate-left-4px {
  transform: translateX(-4px) !important;
}
.translate-left-6px {
  transform: translateX(-6px) !important;
}
.translate-left-8px {
  transform: translateX(-8px) !important;
}
.translate-left-10px {
  transform: translateX(-10px) !important;
}
.translate-left-20px {
  transform: translateX(-20px) !important;
}
.translate-right-2px {
  transform: translateX(2px) !important;
}
.translate-right-4px {
  transform: translateX(4px) !important;
}
.translate-right-6px {
  transform: translateX(6px) !important;
}
.translate-right-8px {
  transform: translateX(8px) !important;
}
.translate-right-10px {
  transform: translateX(10px) !important;
}

/* position */
.top-15px {
  top: 15px !important;
}
.top-20px {
  top: 20px !important;
}
.top-40px {
  top: 40px !important;
}
.top-60px {
  top: 60px !important;
}
.top-80px {
  top: 80px !important;
}
.left-15px {
  left: 15px !important;
}
.left-20px {
  left: 20px !important;
}
.left-40px {
  left: 40px !important;
}
.left-60px {
  left: 60px !important;
}
.left-80px {
  left: 80px !important;
}
.right-15px {
  right: 15px !important;
}
.right-20px {
  right: 20px !important;
}
.right-40px {
  right: 40px !important;
}
.right-60px {
  right: 60px !important;
}
.right-80px {
  right: 80px !important;
}
.bottom-15px {
  bottom: 15px !important;
}
.bottom-20px {
  bottom: 20px !important;
}
.bottom-40px {
  bottom: 40px !important;
}
.bottom-60px {
  bottom: 60px !important;
}
.bottom-80px {
  bottom: 80px !important;
}

/* flex-spacing */
.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex-top {
  display: flex !important;
  align-items: flex-start !important;
}
.flex-center-top {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
}
.flex-bottom {
  display: flex !important;
  align-items: flex-end !important;
}
.flex-right {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-between {
  display: flex !important;
  justify-content: space-between !important;
}
.flex-left-center {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex-right-center {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.flex-between-center {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

/* z index */
.z-overlap-chat {
  z-index: 1000001 !important;
}
