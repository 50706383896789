/* Fonts */
.text-display-xl-med {
  font-weight: 500;
  font-size: 64px;
  line-height: 105px;
  letter-spacing: 0;
}
.text-display-lg-med {
  font-weight: 500;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: 0;
}
.text-display-lg-reg {
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0;
}
.text-display-md-med {
  font-weight: 500;
  font-size: 32px;
  line-height: 52px;
  letter-spacing: 0;
}
.text-display-md-reg {
  font-weight: 400;
  font-size: 31px;
  line-height: 32px;
  letter-spacing: 0;
}
.text-heading-lg-med {
  font-weight: 500;
  font-size: 28px;
  line-height: 46px;
  letter-spacing: 0;
}
.text-heading-md-med {
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0;
}
.text-title-xl-med {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0;
}
.text-title-lg-med {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0;
}
.text-title-md-med {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
}
.text-title-s-med {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
}
.text-title-xs-med {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}
.text-body-lg-reg {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
}
.text-body-s-reg {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
}
.text-body-xs-reg {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.01em;
}
.text-label-input-reg {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.005em;
}
.text-label-lg-med {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.005em;
}
.text-label-md-med {
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.005em;
}
.text-label-md-reg {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.005em;
}
.text-label-s-med {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.005em;
}
.text-label-s-reg {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.005em;
}
.text-label-xs-reg {
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.01em;
}
.text-button-md-med {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
}
.text-button-xl-med {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.01em;
}
.text-spacing-0 {
  letter-spacing: 0;
}
.text-card-s-med {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
}
.text-sidebar-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0;
}
/* Colors */
.text-blue-dark {
  color: var(--blue-dark) !important;
}
.text-blue-main {
  color: var(--blue-main) !important;
}
.text-blue-light {
  color: var(--blue-light) !important;
}
.text-blue-soft {
  color: var(--blue-soft) !important;
}
.text-egg-blue-dark {
  color: var(--egg-blue-dark) !important;
}
.text-egg-blue-main {
  color: var(--egg-blue-main) !important;
}
.text-egg-blue-light {
  color: var(--egg-blue-light) !important;
}
.text-tomato-dark {
  color: var(--tomato-dark) !important;
}
.text-tomato-main {
  color: var(--tomato-main) !important;
}
.text-tomato-light {
  color: var(--tomato-light) !important;
}
.text-tomato-soft {
  color: var(--tomato-soft) !important;
}
.text-saffron-dark {
  color: var(--saffron-dark) !important;
}
.text-saffron-main {
  color: var(--saffron-main) !important;
}
.text-saffron-light {
  color: var(--saffron-light) !important;
}
.text-cyan-dark {
  color: var(--cyan-dark) !important;
}
.text-cyan-main {
  color: var(--cyan-main) !important;
}
.text-cyan-light {
  color: var(--cyan-light) !important;
}
.text-cyan-soft {
  color: var(--cyan-soft) !important;
}
.text-heavy-100 {
  color: var(--heavy-blue-100) !important;
}
.text-heavy-80 {
  color: var(--heavy-blue-80) !important;
}
.text-heavy-60 {
  color: var(--heavy-blue-60) !important;
}
.text-heavy-50 {
  color: var(--heavy-blue-50) !important;
}
.text-heavy-40 {
  color: var(--heavy-blue-40) !important;
}
.text-heavy-20 {
  color: var(--heavy-blue-20) !important;
}
.text-heavy-10 {
  color: var(--heavy-blue-10) !important;
}
.text-heavy-1 {
  color: var(--heavy-blue-1) !important;
}
.text-primary-white {
  color: var(--primary-white) !important;
}
.text-primary-black {
  color: var(--primary-black) !important;
}
.text-inherit {
  color: inherit !important;
}

.hover-text-blue-dark:hover {
  color: var(--blue-dark) !important;
}
.hover-text-blue-main:hover {
  color: var(--blue-main) !important;
}
.hover-text-blue-light:hover {
  color: var(--blue-light) !important;
}
.hover-text-blue-soft:hover {
  color: var(--blue-soft) !important;
}
.hover-text-egg-blue-dark:hover {
  color: var(--egg-blue-dark) !important;
}
.hover-text-egg-blue-main:hover {
  color: var(--egg-blue-main) !important;
}
.hover-text-egg-blue-light:hover {
  color: var(--egg-blue-light) !important;
}
.hover-text-tomato-dark:hover {
  color: var(--tomato-dark) !important;
}
.hover-text-tomato-main:hover {
  color: var(--tomato-main) !important;
}
.hover-text-tomato-light:hover {
  color: var(--tomato-light) !important;
}
.hover-text-tomato-soft:hover {
  color: var(--tomato-soft) !important;
}
.hover-text-saffron-dark:hover {
  color: var(--saffron-dark) !important;
}
.hover-text-saffron-main:hover {
  color: var(--saffron-main) !important;
}
.hover-text-saffron-light:hover {
  color: var(--saffron-light) !important;
}
.hover-text-cyan-dark:hover {
  color: var(--cyan-dark) !important;
}
.hover-text-cyan-main:hover {
  color: var(--cyan-main) !important;
}
.hover-text-cyan-light:hover {
  color: var(--cyan-light) !important;
}
.hover-text-cyan-soft:hover {
  color: var(--cyan-soft) !important;
}
.hover-text-heavy-100:hover {
  color: var(--heavy-blue-100) !important;
}
.hover-text-heavy-80:hover {
  color: var(--heavy-blue-80) !important;
}
.hover-text-heavy-60:hover {
  color: var(--heavy-blue-60) !important;
}
.hover-text-heavy-50:hover {
  color: var(--heavy-blue-50) !important;
}
.hover-text-heavy-40:hover {
  color: var(--heavy-blue-40) !important;
}
.hover-text-heavy-20:hover {
  color: var(--heavy-blue-20) !important;
}
.hover-text-heavy-10:hover {
  color: var(--heavy-blue-10) !important;
}
.hover-text-heavy-1:hover {
  color: var(--heavy-blue-1) !important;
}
.hover-text-primary-white:hover {
  color: var(--primary-white) !important;
}

.bg-blue-dark {
  background-color: var(--blue-dark) !important;
}
.bg-blue-main {
  background-color: var(--blue-main) !important;
}
.bg-blue-light {
  background-color: var(--blue-light) !important;
}
.bg-blue-soft {
  background-color: var(--blue-soft) !important;
}
.bg-egg-blue-dark {
  background-color: var(--egg-blue-dark) !important;
}
.bg-egg-blue-main {
  background-color: var(--egg-blue-main) !important;
}
.bg-egg-blue-light {
  background-color: var(--egg-blue-light) !important;
}
.bg-tomato-dark {
  background-color: var(--tomato-dark) !important;
}
.bg-tomato-main {
  background-color: var(--tomato-main) !important;
}
.bg-tomato-light {
  background-color: var(--tomato-light) !important;
}
.bg-tomato-soft {
  background-color: var(--tomato-soft) !important;
}
.bg-saffron-dark {
  background-color: var(--saffron-dark) !important;
}
.bg-saffron-main {
  background-color: var(--saffron-main) !important;
}
.bg-saffron-light {
  background-color: var(--saffron-light) !important;
}
.bg-cyan-dark {
  background-color: var(--cyan-dark) !important;
}
.bg-cyan-main {
  background-color: var(--cyan-main) !important;
}
.bg-cyan-light {
  background-color: var(--cyan-light) !important;
}
.bg-cyan-soft {
  background-color: var(--cyan-soft) !important;
}
.bg-heavy-100 {
  background-color: var(--heavy-blue-100) !important;
}
.bg-heavy-80 {
  background-color: var(--heavy-blue-80) !important;
}
.bg-heavy-60 {
  background-color: var(--heavy-blue-60) !important;
}
.bg-heavy-50 {
  background-color: var(--heavy-blue-50) !important;
}
.bg-heavy-40 {
  background-color: var(--heavy-blue-40) !important;
}
.bg-heavy-20 {
  background-color: var(--heavy-blue-20) !important;
}
.bg-heavy-10 {
  background-color: var(--heavy-blue-10) !important;
}
.bg-heavy-1 {
  background-color: var(--heavy-blue-1) !important;
}
.bg-primary-white {
  background-color: var(--primary-white) !important;
}
.bg-blue-transparent {
  background-color: var(--heavy-blue-transparent) !important;
}
.bg-white-transparent {
  background-color: var(--primary-white-transparent) !important;
}

.hover-bg-blue-dark:hover {
  background-color: var(--blue-dark) !important;
}
.hover-bg-blue-main:hover {
  background-color: var(--blue-main) !important;
}
.hover-bg-blue-light:hover {
  background-color: var(--blue-light) !important;
}
.hover-bg-blue-soft:hover {
  background-color: var(--blue-soft) !important;
}
.hover-bg-egg-blue-dark:hover {
  background-color: var(--egg-blue-dark) !important;
}
.hover-bg-egg-blue-main:hover {
  background-color: var(--egg-blue-main) !important;
}
.hover-bg-egg-blue-light:hover {
  background-color: var(--egg-blue-light) !important;
}
.hover-bg-tomato-dark:hover {
  background-color: var(--tomato-dark) !important;
}
.hover-bg-tomato-main:hover {
  background-color: var(--tomato-main) !important;
}
.hover-bg-tomato-light:hover {
  background-color: var(--tomato-light) !important;
}
.hover-bg-tomato-soft:hover {
  background-color: var(--tomato-soft) !important;
}
.hover-bg-saffron-dark:hover {
  background-color: var(--saffron-dark) !important;
}
.hover-bg-saffron-main:hover {
  background-color: var(--saffron-main) !important;
}
.hover-bg-saffron-light:hover {
  background-color: var(--saffron-light) !important;
}
.hover-bg-cyan-dark:hover {
  background-color: var(--cyan-dark) !important;
}
.hover-bg-cyan-main:hover {
  background-color: var(--cyan-main) !important;
}
.hover-bg-cyan-light:hover {
  background-color: var(--cyan-light) !important;
}
.hover-bg-cyan-soft:hover {
  background-color: var(--cyan-soft) !important;
}
.hover-bg-heavy-100:hover {
  background-color: var(--heavy-blue-100) !important;
}
.hover-bg-heavy-80:hover {
  background-color: var(--heavy-blue-80) !important;
}
.hover-bg-heavy-60:hover {
  background-color: var(--heavy-blue-60) !important;
}
.hover-bg-heavy-50:hover {
  background-color: var(--heavy-blue-50) !important;
}
.hover-bg-heavy-40:hover {
  background-color: var(--heavy-blue-40) !important;
}
.hover-bg-heavy-20:hover {
  background-color: var(--heavy-blue-20) !important;
}
.hover-bg-heavy-10:hover {
  background-color: var(--heavy-blue-10) !important;
}
.hover-bg-heavy-1:hover {
  background-color: var(--heavy-blue-1) !important;
}
.hover-bg-primary-white:hover {
  background-color: var(--primary-white) !important;
}

.border-blue-dark {
  border-color: var(--blue-dark) !important;
}
.border-blue-main {
  border-color: var(--blue-main) !important;
}
.border-blue-light {
  border-color: var(--blue-light) !important;
}
.border-blue-soft {
  border-color: var(--blue-soft) !important;
}
.border-egg-blue-dark {
  border-color: var(--egg-blue-dark) !important;
}
.border-egg-blue-main {
  border-color: var(--egg-blue-main) !important;
}
.border-egg-blue-light {
  border-color: var(--egg-blue-light) !important;
}
.border-tomato-dark {
  border-color: var(--tomato-dark) !important;
}
.border-tomato-main {
  border-color: var(--tomato-main) !important;
}
.border-tomato-light {
  border-color: var(--tomato-light) !important;
}
.border-tomato-soft {
  border-color: var(--tomato-soft) !important;
}
.border-saffron-dark {
  border-color: var(--saffron-dark) !important;
}
.border-saffron-main {
  border-color: var(--saffron-main) !important;
}
.border-saffron-light {
  border-color: var(--saffron-light) !important;
}
.border-cyan-dark {
  border-color: var(--cyan-dark) !important;
}
.border-cyan-main {
  border-color: var(--cyan-main) !important;
}
.border-cyan-light {
  border-color: var(--cyan-light) !important;
}
.border-cyan-soft {
  border-color: var(--cyan-soft) !important;
}
.border-heavy-100 {
  border-color: var(--heavy-blue-100) !important;
}
.border-heavy-80 {
  border-color: var(--heavy-blue-80) !important;
}
.border-heavy-60 {
  border-color: var(--heavy-blue-60) !important;
}
.border-heavy-50 {
  border-color: var(--heavy-blue-50) !important;
}
.border-heavy-40 {
  border-color: var(--heavy-blue-40) !important;
}
.border-heavy-20 {
  border-color: var(--heavy-blue-20) !important;
}
.border-heavy-10 {
  border-color: var(--heavy-blue-10) !important;
}
.border-heavy-1 {
  border-color: var(--heavy-blue-1) !important;
}
.border-primary-white {
  border-color: var(--primary-white) !important;
}

.hover-border-blue-dark:hover {
  border-color: var(--blue-dark) !important;
}
.hover-border-blue-main:hover {
  border-color: var(--blue-main) !important;
}
.hover-border-blue-light:hover {
  border-color: var(--blue-light) !important;
}
.hover-border-blue-soft:hover {
  border-color: var(--blue-soft) !important;
}
.hover-border-egg-blue-dark:hover {
  border-color: var(--egg-blue-dark) !important;
}
.hover-border-egg-blue-main:hover {
  border-color: var(--egg-blue-main) !important;
}
.hover-border-egg-blue-light:hover {
  border-color: var(--egg-blue-light) !important;
}
.hover-border-tomato-dark:hover {
  border-color: var(--tomato-dark) !important;
}
.hover-border-tomato-main:hover {
  border-color: var(--tomato-main) !important;
}
.hover-border-tomato-light:hover {
  border-color: var(--tomato-light) !important;
}
.hover-border-tomato-soft:hover {
  border-color: var(--tomato-soft) !important;
}
.hover-border-saffron-dark:hover {
  border-color: var(--saffron-dark) !important;
}
.hover-border-saffron-main:hover {
  border-color: var(--saffron-main) !important;
}
.hover-border-saffron-light:hover {
  border-color: var(--saffron-light) !important;
}
.hover-border-cyan-dark:hover {
  border-color: var(--cyan-dark) !important;
}
.hover-border-cyan-main:hover {
  border-color: var(--cyan-main) !important;
}
.hover-border-cyan-light:hover {
  border-color: var(--cyan-light) !important;
}
.hover-border-cyan-soft:hover {
  border-color: var(--cyan-soft) !important;
}
.hover-border-heavy-100:hover {
  border-color: var(--heavy-blue-100) !important;
}
.hover-border-heavy-80:hover {
  border-color: var(--heavy-blue-80) !important;
}
.hover-border-heavy-60:hover {
  border-color: var(--heavy-blue-60) !important;
}
.hover-border-heavy-50:hover {
  border-color: var(--heavy-blue-50) !important;
}
.hover-border-heavy-40:hover {
  border-color: var(--heavy-blue-40) !important;
}
.hover-border-heavy-20:hover {
  border-color: var(--heavy-blue-20) !important;
}
.hover-border-heavy-10:hover {
  border-color: var(--heavy-blue-10) !important;
}
.hover-border-heavy-1:hover {
  border-color: var(--heavy-blue-1) !important;
}
.hover-border-primary-white:hover {
  border-color: var(--primary-white) !important;
}

/* Shadows */
.shadow-level-1 {
  box-shadow: var(--shadow-level-1);
}
.shadow-level-2 {
  box-shadow: var(--shadow-level-2);
}
.shadow-level-3 {
  box-shadow: var(--shadow-level-3);
}
.shadow-white-2px {
  box-shadow: 0 0 0 2px var(--primary-white);
}
.drop-shadow-level-1 {
  filter: drop-shadow(var(--shadow-level-1));
}
.drop-shadow-level-2 {
  filter: drop-shadow(var(--shadow-level-2));
}
.drop-shadow-level-3 {
  filter: drop-shadow(var(--shadow-level-3));
}

/* Striped background */
.bg-striped {
  background: repeating-linear-gradient(120deg, #f7faff, #f7faff 4px, #e1e3eb 4px, #e1e3eb 8px);
}

/* transition */
.transition-width {
  transition: width 0.5s;
}

/* break-word */
.overflow-wrap {
  overflow-wrap: anywhere;
}

/* allow labels with empty lines */
.white-space-pre-line {
  white-space: pre-line;
}

/* icon sizes for 24-outline icons with constant stroke-width 1.5px*/
.icon-48px {
  width: 48px;
  height: 48px;
  stroke-width: 0.75;
}
.icon-40px {
  width: 40px;
  height: 40px;
  stroke-width: 0.9;
}
.icon-28px-bold {
  width: 28px;
  height: 28px;
  stroke-width: 2;
}
.icon-28px {
  width: 28px;
  height: 28px;
  stroke-width: 1.5;
}
.icon-24px {
  width: 24px;
  height: 24px;
  stroke-width: 1.5;
}
.icon-20px {
  width: 20px;
  height: 20px;
  stroke-width: 1.8;
}
.icon-18px {
  width: 18px;
  height: 18px;
  stroke-width: 2;
}
.icon-16px {
  width: 16px;
  height: 16px;
  stroke-width: 2;
}
.icon-14px {
  width: 14px;
  height: 14px;
  stroke-width: 2.5;
}
.icon-12px {
  width: 12px;
  height: 12px;
  stroke-width: 2.5;
}
.icon-8px {
  width: 8px;
  height: 8px;
  stroke-width: 3;
}
