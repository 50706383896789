html,
body {
  overflow: hidden;
}

.private-layout-menu {
  width: 240px;
}
.private-layout-container {
  width: calc(100vw - 240px);
  display: flex;
  justify-content: center;
}
.private-layout-promo {
  width: 170px;
  padding: 20px;
}
.private-layout-content {
  /* 864 + 24 + 24 = 912px */
  max-width: min(100vw, 912px) !important;
  margin: auto;
  padding: 28px 24px 22px;
}
.private-layout-content.full-screen {
  max-width: 100vw !important;
}

/* authentification */
.auth-image-1 {
  background-image: url('../assets/images/authImage1.jpg');
}
.auth-image-2 {
  background-image: url('../assets/images/authImage2.jpg');
}
.auth-image-3 {
  background-image: url('../assets/images/authImage3.jpg');
}
.auth-image-4 {
  background-image: url('../assets/images/authImage4.jpg');
}
.auth-image-5 {
  background-image: url('../assets/images/authImage5.jpg');
}
.auth-image-6 {
  background-image: url('../assets/images/authImage6.jpg');
}
.auth-image-7 {
  background-image: url('../assets/images/authImage7.jpg');
}

.auth-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 48px 0 24px 120px;
}
.auth-footer-container {
  flex: none;
  background-color: var(--heavy-blue-1);
  border: 1px solid var(--heavy-blue-20);
  padding: 25px;
}
.auth-logo-container {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 48px;
}
.auth-logo {
  height: 48px;
}
.auth-dropdown {
  height: fit-content;
  border-color: var(--primary-white);
}
.auth-dropdown .p-dropdown-trigger {
  display: none;
}
.auth-spacer-1 {
  flex: 5 5;
  padding-top: 24px;
}
.auth-spacer-2 {
  flex: 9 9;
  padding-top: 24px;
}
.auth-title-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 48px;
}
.auth-title {
  font-weight: 500;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: 0;
}
.auth-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
}
.auth-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 360px;
  padding-top: 40px;
}
.auth-trial-message {
  width: 360px;
}
.auth-options-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
}
.auth-options-link {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
}
.auth-button {
  width: 100%;
  height: 72px;
  padding: 19px;
  border-radius: 10px;
  border: 1px solid var(--heavy-blue-20);
  background-color: var(--primary-white);
  color: var(--heavy-blue-100);
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
}
.auth-button:enabled:hover,
.auth-button:not(button):not(a):not(.p-disabled):hover {
  border-color: var(--heavy-blue-40);
  background-color: var(--primary-white);
  color: var(--heavy-blue-100);
}

.weekday-button-bar {
  display: flex;
  gap: 4px;
}
.weekday-button-bar .p-button {
  width: 64px;
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-60);
  border: 1px solid var(--heavy-blue-1) !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.weekday-button-bar .p-button:hover {
  background: var(--blue-soft) !important;
  border-color: var(--blue-soft) !important;
  color: var(--blue-main) !important;
}
.weekday-button-bar .p-button.p-highlight {
  background: var(--blue-light);
  border-color: var(--blue-main) !important;
  color: var(--blue-main);
}
.weekday-button-bar .p-button.p-highlight:hover {
  background: var(--blue-light);
  border-color: var(--blue-dark) !important;
  color: var(--blue-dark);
}

.custom-file-upload {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.custom-file-upload input[type='file'] {
  display: none;
}

/* Sumoist Message Block */
.sumoist-container {
  position: relative;
  padding: 54px 15px 9px;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--blue-light);
  border-radius: 14px;
}
.sumoist {
  position: absolute;
  left: 0;
  top: -24px;
  cursor: pointer;
}
.sumoist-chick-1,
.sumoist-chick-2 {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(19px, -29px);
  background-color: #fe4a4900;
  transition: background-color 1s;
}
.sumoist-chick-2 {
  transform: translate(50px, -35px);
}
.sumoist:hover .sumoist-chick-1,
.sumoist:hover .sumoist-chick-2 {
  background-color: #fe4a4950;
  transition: background-color 1s 0.5s;
}

/* crisp chat button */
.crisp-client .cc-1brb6 span.cc-1kgzy {
  background-color: #33c1b1 !important;
}

/* Background Card */
.sumo-card-bg {
  background: var(--heavy-blue-1);
  padding: 28px 24px 24px;
  border-radius: 16px;
}

/* sumo card */
.sumo-card {
  background: var(--primary-white);
  padding: 0;
  margin-bottom: 0;
  box-shadow: var(--shadow-level-1);
  border: 2px solid var(--primary-white);
  border-radius: 14px;
}
.sumo-card-inactive {
  background: var(--heavy-blue-1);
  border-color: var(--heavy-blue-1);
}
.sumo-card .card-delimeter {
  border-bottom: 1px solid var(--heavy-blue-20);
  margin-left: -2px;
  margin-right: -2px;
}
.sumo-card.action-card {
  cursor: pointer;
}
.sumo-card.action-card:hover {
  box-shadow: var(--shadow-level-3);
}
.sumo-card.action-card.selected {
  box-shadow: var(--shadow-level-2);
  border-color: var(--blue-main);
}
.sumo-card.action-card.selected:hover {
  box-shadow: var(--shadow-level-3);
  border-color: var(--blue-dark);
}
.sumo-card.action-card.selected .card-delimeter {
  margin-left: 0;
  margin-right: 0;
}
.sumo-card.action-card:hover .card-button.button-blue {
  color: var(--blue-dark);
}
.sumo-card.action-card:hover .card-button.button-blue.p-button-outlined {
  color: var(--blue-dark);
  border-color: var(--blue-dark);
}
.sumo-card .card-selector .p-checkbox-box:not(.p-highlight) {
  background: var(--primary-white);
}
.sumo-card .card-locked-label,
.sumo-card .card-selector-hidden .p-checkbox-box,
.sumo-card .card-element-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 200ms linear;
}
.sumo-card:hover .card-locked-label,
.sumo-card:hover .card-selector .p-checkbox-box,
.sumo-card:hover .card-element-hidden {
  visibility: visible;
  opacity: 1;
}
.sumo-card:hover .card-selector .p-checkbox-box:not(.p-highlight):hover {
  border-color: var(--heavy-blue-80);
}

/* sumo-create-card */
.sumo-create-card {
  color: var(--blue-main);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.sumo-create-card:hover {
  color: var(--blue-dark);
}

/* Big White Button with shadow */
.button-white {
  height: 48px;
  color: var(--heavy-blue-60);
  background: var(--primary-white);
  border-color: var(--primary-white);
  box-shadow: var(--shadow-level-1);
}
.button-white:focus,
.button-white.p-focus {
  border-color: var(--primary-white) !important;
  box-shadow: var(--shadow-level-2) !important;
}
.button-white:enabled:hover,
.button-white:enabled:active,
.button-white:not(button):not(a):not(.p-disabled):hover,
.button-white:not(button):not(a):not(.p-disabled):active {
  color: var(--heavy-blue-100);
  background: var(--primary-white);
  border-color: var(--primary-white);
  box-shadow: var(--shadow-level-2);
}
.button-white.selected {
  color: var(--blue-main);
  box-shadow: var(--shadow-level-2);
}

/* action button */
.action-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0;
  border-radius: 8px;
  border-width: 0;
  color: var(--heavy-blue-50);
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-button-sm {
  width: 24px !important;
  height: 24px !important;
  padding: 0;
  border-radius: 6px;
  border-width: 0;
  color: var(--heavy-blue-50);
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-button-xs {
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  border-radius: 4px;
  border-width: 0;
  color: var(--heavy-blue-50);
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-button:not(.p-disabled):hover,
.action-button-sm:not(.p-disabled):hover,
.action-button-xs:not(.p-disabled):hover {
  color: var(--heavy-blue-80) !important;
  background-color: var(--heavy-blue-1) !important;
}

/* action button */
.add-button {
  height: 24px;
  padding: 0;
  border-radius: 10px;
  border-width: 0;
  color: var(--heavy-blue-60);
  background-color: var(--heavy-blue-10);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-button-lg {
  height: 32px;
  padding: 0;
  border-radius: 10px;
  border-width: 0;
  color: var(--heavy-blue-60);
  background-color: var(--heavy-blue-10);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-button-input {
  height: 16px;
  padding: 0;
  border-radius: 8px;
  border-width: 0;
  color: var(--heavy-blue-60);
  background-color: var(--heavy-blue-10);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-button:not(.p-disabled):hover,
.add-button-lg:not(.p-disabled):hover,
.add-button-input:not(.p-disabled):hover {
  color: var(--blue-main) !important;
  background-color: var(--blue-light) !important;
}

/* multiselect */
.multiselect-wrap .p-multiselect-label {
  white-space: normal;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
}

.p-multiselect .p-multiselect-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.005rem;
}

/* schedule tabs */
.schedule-tab-container {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: hidden;
}
.schedule-tab {
  height: 60px;
  padding: 20px 12px;
  color: var(--heavy-blue-80);
  background: var(--primary-white);
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.schedule-tab.schedule-tab-selected {
  box-shadow: 0px 4px 16px 0px #0e243b0f;
  color: #006ce0;
}
.schedule-tab:hover,
.schedule-tab:focus {
  color: var(--heavy-blue-100);
  background: var(--heavy-blue-20);
}

.schedule-tab-hidden {
  visibility: hidden;
}

/* error message */
.error-message {
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.01em;
  color: var(--tomato-dark);
  background-color: var(--tomato-soft);
  border-radius: 4px;
  gap: 6px;
  padding: 6px;
}

/* Booking Container */
.booking-container {
  display: flex;
  height: 100%;
  background: var(--primary-white);
}
.booking-container.booking-container-icon {
  position: relative;
  width: 78px;
  height: 56px;
  border-radius: 8px;
  overflow: hidden;
}
.booking-background {
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.booking-card-container {
  max-width: 100vw;
  max-height: 100vh;
  max-height: 100dvh;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.footer-space {
  display: none;
  flex: none;
}

/* Booking Card */
.booking-card {
  width: 100%;
  max-width: 840px;
  height: 100%;
  max-height: 520px;
  background: var(--primary-white);
  border-radius: 16px;
  box-shadow: var(--shadow-level-1);
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.booking-card.card-iframe {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  box-shadow: none;
}
.booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 27px;
  border: 1px solid var(--heavy-blue-20);
  border-radius: 16px 16px 0 0;
}
.booking-title {
  padding: 21px 0 15px;
  font-weight: 500;
  font-size: 28px;
  line-height: 46px;
}
.booking-logo {
  height: 48px;
}

/* booking step */
.booking-content {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}
.booking-details {
  width: 280px;
  padding: 20px 0 20px 28px;
  display: flex;
  flex-direction: column;
}
.booking-details > .booking-hosts {
  display: flex;
  padding-bottom: 8px;
}
.booking-details .details-button {
  display: none;
  justify-content: center;
  padding: 0;
  width: 48px;
  height: 48px;
  color: var(--heavy-blue-80);
  border-radius: 10px;
}
.booking-details .booking-buttons {
  display: flex;
  gap: 12px;
  margin-top: auto;
}
.booking-details .booking-buttons .button-book,
.booking-details .booking-buttons .button-cancel {
  min-width: 120px;
  justify-content: center;
}

.booking-time-container {
  flex-grow: 1;
  display: flex;
}
.booking-calendar {
  width: 356px;
}
.booking-slots-container {
  flex-grow: 1;
  overflow: hidden;
  border-radius: 0 0 16px 0;
}
.booking-slots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 32px 0;
}
.booking-info-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.booking-info {
  width: 100%;
  max-width: 400px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

/* booked step */
.booked-content {
  flex-grow: 1;
  padding: 12px 28px 0;
  overflow: hidden;
}
.booked-content .booked-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.booked-content .booked-message {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
}
.booked-content .booked-guests {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}
.booked-content .booked-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 0 22px;
}
.booked-content .booking-details {
  padding: 24px 0 0;
}
.booked-content .booked-buttons > .buttons-header {
  display: none;
  padding: 19px 16px 18px 20px;
  justify-content: space-between;
  border-bottom: 1px solid var(--heavy-blue-20);
  cursor: pointer;
}
.booked-content .booked-buttons > .buttons-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.booked-content .booked-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.booked-content .booked-manage-space {
  display: none;
  min-height: 97px;
  border-top: 1px solid var(--heavy-blue-20);
}
.booked-content .booked-manage {
  display: none;
  padding: 23px 20px 24px;
  border-top: 1px solid var(--heavy-blue-20);
  background: var(--primary-white);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

/* potential hosts step */
.potential-hosts-container {
  padding: 20px 28px;
}
.potential-hosts-buttons {
  padding: 12px 28px;
  flex: none;
  display: flex;
  gap: 8px;
  border-top: 1px solid var(--heavy-blue-20);
}

/* locations step */
.locations-content {
  flex-grow: 1;
  padding: 0;
  overflow: hidden;
}
.locations-content .location-info {
  display: flex;
  height: 100%;
}
.locations-content .location-search-container {
  padding: 20px 28px 0;
}
.locations-content .location-cards-container {
  padding: 32px 28px 20px;
}
.locations-content .location-card {
  border: 1px solid var(--heavy-blue-20);
  border-radius: 8px;
  padding: 9px 13px;
  height: 100%;
  cursor: pointer;
}
.locations-content .location-card-selected {
  border: 2px solid var(--blue-main);
  padding: 8px 12px;
}
.locations-content .location-card:hover {
  box-shadow: var(--shadow-level-1);
}
.locations-content .location-map {
  flex: 1 1;
}
.locations-content .locations-buttons {
  padding: 12px 28px;
  border-top: 1px solid var(--heavy-blue-20);
  display: flex;
  gap: 12px;
}
.location-card-no-map {
  width: calc(33% - 8px);
}

/* journey step */
.journey-content {
  flex-grow: 1;
  overflow: hidden;
}

/* booking-footer */
.booking-footer {
  position: absolute;
  bottom: 20px;
}
.booking-footer a {
  color: var(--heavy-blue-80);
}

/* Booking Background Types*/
.booking-container.bg-full .booking-background {
  display: block;
  background-color: #00000050;
  background-blend-mode: color;
}
.booking-container.bg-full .booking-footer a {
  color: var(--primary-white);
}

.booking-container.bg-top {
  background: var(--heavy-blue-1);
}
.booking-container.bg-top .booking-background {
  height: 33%;
}

.booking-container.bg-left .booking-background,
.booking-container.bg-right .booking-background {
  width: 30%;
  display: block;
  position: relative;
}
.booking-container.bg-right .booking-background {
  order: 1;
}
.booking-container.bg-blank .booking-background {
  display: none;
}
.booking-container.bg-left .booking-card-container .booking-card,
.booking-container.bg-right .booking-card-container .booking-card,
.booking-container.bg-blank .booking-card-container .booking-card {
  box-shadow: none;
}
.booking-container.bg-left .booking-card-container .booking-card .booking-header,
.booking-container.bg-right .booking-card-container .booking-card .booking-header,
.booking-container.bg-blank .booking-card-container .booking-card .booking-header {
  border-width: 0 0 1px;
}

/* time-slot-selector */
.time-slot-selector {
  color: var(--heavy-blue-60);
  background: var(--heavy-blue-1);
  border: 1px solid var(--heavy-blue-1);
  width: fit-content;
  padding: 11px 10px 9px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}
.time-slot-selector:hover {
  color: var(--booking-page-color);
}
.time-slot-selector.selected {
  color: var(--booking-page-color);
  border-color: var(--booking-page-color);
}
.time-slot-selector.selected:hover {
  background: var(--blue-soft);
}

/* sumo-filter */
.sumo-filter .p-multiselect {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--heavy-blue-80);
  background: var(--primary-white);
  border-radius: 6px;
  border: none;
}
.sumo-filter .p-multiselect:not(.p-disabled):hover,
.sumo-filter .p-multiselect:not(.p-disabled).p-focus {
  color: var(--heavy-blue-100);
  background: var(--heavy-blue-1);
}
.sumo-filter .p-multiselect .p-multiselect-label {
  padding: 5px 6px 5px 10px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.sumo-filter .p-multiselect .p-multiselect-trigger {
  padding: 8px 10px 0 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sumo-filter .p-dropdown {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--heavy-blue-80);
  background: var(--primary-white);
  border-radius: 4px;
  border: none;
}
.sumo-filter .p-dropdown .p-dropdown-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--heavy-blue-80);
}
.sumo-filter .p-dropdown:not(.p-disabled):hover,
.sumo-filter .p-dropdown:not(.p-disabled).p-focus {
  color: var(--heavy-blue-100);
  background: var(--heavy-blue-1);
}
.sumo-filter .p-dropdown .p-dropdown-label {
  padding: 5px 6px 5px 10px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.sumo-filter .p-dropdown .p-dropdown-trigger {
  width: 24px;
  padding-right: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sumo-filter .sumo-badge {
  padding: 4px 6px;
  background: var(--blue-light);
  border-radius: 6px;
  color: var(--blue-dark);
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.005em;
}

/* sumo-bulk-action */
.sumo-bulk-action-row {
  padding: 8px 8px 8px 20px;
  background: var(--heavy-blue-100);
  box-shadow: var(--shadow-level-2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: fixed;
  bottom: 32px;
  z-index: 10;
}
.sumo-bulk-action-row .p-button.p-button-text {
  color: var(--primary-white);
}
.sumo-bulk-action-row .p-button.p-button-text:enabled:hover,
.sumo-bulk-action-row .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.sumo-bulk-action-row .p-button.p-button-text:enabled:active,
.sumo-bulk-action-row .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.sumo-bulk-action-row .p-button.p-button-text:focus {
  color: var(--heavy-blue-60);
}
.sumo-bulk-action-content {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--primary-white);
}

.active-badge {
  background: var(--egg-blue-light);
  color: var(--egg-blue-main);
  padding: 6px 8px;
  border-radius: 6px;
}

.inactive-badge {
  background: var(--tomato-light);
  color: var(--tomato-main);
  padding: 6px 8px;
  border-radius: 6px;
}

.warning-badge {
  background: var(--saffron-light);
  color: var(--saffron-main);
  padding: 6px 8px;
  border-radius: 6px;
}

.text-overlapping-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon-picker {
  width: 200px;
  height: 300px;
  overflow-y: auto;
}

.icon-picker .p-overlaypanel-content {
  padding: 6px;
}

.journey-canvas {
  background: var(--heavy-blue-1);
  height: calc(100vh - 278px);
  box-shadow: inset var(--shadow-level-1);
}

.journey-canvas .plus-button {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 79, 163, 0.2);
}

.journey-canvas .plus-button:not(.plus-node-disabled):hover {
  background: rgba(0, 79, 163, 0.3);
  box-shadow: var(--shadow-level-1);
}

.journey-canvas .start-node {
  width: 228px;
  background: var(--heavy-blue-80);
  color: var(--primary-white);
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: auto;
  box-shadow: var(--shadow-level-1);
}

.journey-canvas .start-plus-node {
  width: 228px;
  height: 108px;
  border: 1px #004fa366 dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-level-1);
}

.journey-canvas .start-plus-node:not(.plus-node-disabled):hover {
  border-color: #004fa3bb;
}

.journey-canvas .start-plus-node:not(.plus-node-disabled):hover .plus-button {
  background: rgba(0, 79, 163, 0.3);
  box-shadow: var(--shadow-level-1);
}

.journey-canvas .plus-node-disabled {
  opacity: 0.8;
  cursor: auto !important;
}

.journey-canvas .step-node {
  width: 228px;
  min-height: 108px;
  background: var(--primary-white);
  cursor: pointer;
  border-left: 8px solid;
  border-radius: 8px;
  box-shadow: var(--shadow-level-1);
}

.journey-canvas .step-node:hover {
  box-shadow: var(--shadow-level-2);
}

.journey-canvas .step-node-text {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--heavy-blue-100);
}

.journey-step .journey-step-options {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 200ms linear;
}
.journey-step:hover .journey-step-options {
  visibility: visible;
  opacity: 1;
}

.journey-canvas .step-toolbar-item {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-white);
  color: var(--heavy-blue-50);
  cursor: pointer;
  border-radius: 6px;
}

.journey-canvas .step-toolbar-item:hover {
  color: var(--heavy-blue-60);
}

.p-sidebar-right .journey-sidebar {
  width: max(500px, (100vw - 240px) / 2);
}

.form-fields {
  margin-top: 2px;
  border-radius: 8px;
  box-shadow: var(--shadow-level-2);
  padding: 16px 20px;
}

.form-fields::after,
.form-fields::before {
  display: none;
}

.form-fields .p-overlaypanel-content {
  padding: 0;
}

.form-fields .field-card {
  width: 178px;
  height: 40px;
  padding: 10px 12px 9px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: var(--heavy-blue-1);
  cursor: pointer;
}

.form-fields .field-card:hover {
  background: var(--heavy-blue-10);
}

.p-dialog-right .form-field-modal,
.p-dialog-right .card-modal {
  --sidebar-width: max(500px, (100vw - 240px) / 2);
  width: 380px;
  margin-right: calc((var(--sidebar-width) - 380px) / 2);
}

.journey-page-card {
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: var(--primary-white);
  padding: 16px 20px;
  margin-bottom: 0;
  box-shadow: var(--shadow-level-2);
  border-radius: 14px;
}

.drag-and-drop {
  cursor: grab;
  color: var(--heavy-blue-50);
}

.drag-and-drop:not(.p-disabled):hover {
  color: var(--heavy-blue-80) !important;
}

div:has(> div.scroll-menu) {
  position: relative;
}

.scroll-menu {
  top: 0 !important;
  left: calc(32px - var(--menu-width)) !important;
  right: 0;
  margin-top: 36px !important;
}

.h-line-heavy-blue-20 {
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--heavy-blue-20);
}

.inline-dropdown {
  border: none;
}

.inline-dropdown .p-inputtext {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.inline-dropdown:not(.p-disabled):hover .p-dropdown-label {
  color: inherit;
}

.inline-dropdown .p-dropdown-items-wrapper {
  max-height: fit-content !important;
}

.inline-dropdown .p-dropdown-trigger {
  color: inherit;
  width: 28px;
}

.inline-dropdown .p-dropdown-trigger-icon {
  width: 12px;
}

.ors-changes-up {
  background: var(--cyan-soft);
  color: var(--egg-blue-dark);
  padding: 6px;
  border-radius: 6px;
}

.ors-changes-down {
  background: var(--tomato-light);
  color: var(--tomato-main);
  padding: 6px;
  border-radius: 6px;
}

.cookie-consent-modal {
  height: 368px;
}

.__floater {
  filter: drop-shadow(var(--shadow-level-3)) !important;
}
/* beacon */
.__floater .__floater__arrow > span:after {
  content: '';
  animation: 1.2s ease-in-out 0s infinite normal none running joyride-beacon-inner;
  background-color: var(--blue-main);
  border-radius: 50%;
  display: block;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
}
.__floater[x-placement='right'] .__floater__arrow > span:after {
  left: -18px;
}
.__floater .__floater__arrow > span:before {
  content: '';
  animation: 1.2s ease-in-out 0s infinite normal none running joyride-beacon-outer;
  background-color: var(--blue-main-border);
  border: 2px solid var(--blue-main);
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  left: -36px;
  opacity: 0.9;
  position: absolute;
  top: calc(-50% - 4px);
  width: 36px;
  height: 36px;
}
.__floater[x-placement='right'] .__floater__arrow > span:before {
  left: -36px;
}
.no-maps-connected {
  background-image: url('../assets/images/noMapsConnection.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
}

.no-maps-connected-warning {
  backdrop-filter: blur(8px);
  border-radius: 8px;
}

.p-menuitem-tooltip {
  position: relative;
}

.p-menuitem-tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--heavy-blue-80);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  font-size: 12px;
}

.p-menuitem-tooltip:hover::after {
  opacity: 1;
}

.transparent-border {
  border: 1px solid transparent;
}

.invalid-border {
  border: 1px solid var(--tomato-main) !important;
}

/* sumo divider */
.sumo-divider {
  border-top: 1px solid var(--heavy-blue-20);
}
.sumo-divider-vertical {
  border-left: 1px solid var(--heavy-blue-20);
}

.overflow-hidden {
  overflow: hidden;
}

/* account-menu */
.account-menu {
  position: absolute;
  bottom: 40px;
  left: -16px;
  box-shadow: var(--shadow-level-3);
}

.min-w-300px {
  min-width: 300px !important;
}

.ql-tooltip {
  left: 0 !important;
}
