:root {
  --font-family: 'Poppins', sans-serif;
  --blue-dark: #004fa3;
  --blue-main-border: rgba(0, 108, 224, 20%);
  --blue-main: #006ce0;
  --blue-light: #deebf9;
  --blue-soft: #f2f7fd;
  --egg-blue-dark: #25a7a1;
  --egg-blue-main: #4ad7d1;
  --egg-blue-light: #dcf6f5;
  --tomato-dark: #b02a31;
  --tomato-main: #fe4a49;
  --tomato-light: #f6d9db;
  --tomato-soft: #faf4f4;
  --saffron-dark: #d5a220;
  --saffron-main: #e9c46a;
  --saffron-light: #f8f0db;
  --cyan-dark: #1a615b;
  --cyan-main: #279185;
  --cyan-light: #c5dcda;
  --cyan-soft: #eff4f1;
  --heavy-blue-100: #181f30;
  --heavy-blue-80: #464c59;
  --heavy-blue-60: #6f7584;
  --heavy-blue-50: #acafb7;
  --heavy-blue-40: #c4c7cd;
  --heavy-blue-20: #e1e3e5;
  --heavy-blue-10: #eceff5;
  --heavy-blue-1: #f7f8fa;
  --primary-white: #ffffff;
  --primary-black: #000000;
  --heavy-blue-transparent: #edeeff99;
  --primary-white-transparent: #ffffff99;
  --shadow-focus: 0 0 0 4px #deebf9;
  --shadow-level-1: 0 4px 16px #0e243b0f;
  --shadow-level-2: 0 4px 22px #0e243b1a;
  --shadow-level-3: 0 5px 26px #0e243b26;
  --border-radius: 6px;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--heavy-blue-100);
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: var(--primary-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

 /* fix mobile browsers actual view port height */
.h-screen {
  height: 100dvh !important;
}
.min-h-screen {
  min-height: 100dvh !important;
}
.max-h-screen {
  max-height: 100dvh !important;
}

a {
  color: var(--blue-main);
  text-decoration: none;
}
a:hover {
  color: var(--blue-dark);
}

.p-component {
  font-family: var(--font-family);
}
.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}
.p-component-overlay {
  background-color: #181f3066;
  backdrop-filter: blur(1.5px);
  transition-duration: 0.2s;
}

/* layout mask */
.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
}

/* button */
.p-button {
  color: var(--primary-white);
  background: var(--blue-main);
  border: 1px solid var(--blue-main);
  padding: 9px 17px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01rem;
}
.p-button.p-button-link {
  all: unset;
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
  color: var(--blue-main);
  background: transparent;
  border: transparent;
}
.p-button.button-sm {
  padding: 7px 12px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01rem;
}
.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--blue-dark);
  border-color: var(--blue-dark);
}
.p-button.p-button-link:hover,
.p-button.p-button-link:focus {
  all: unset;
}
.p-button:focus {
  box-shadow: var(--shadow-focus);
}
.p-button .p-button-label {
  transition-duration: 0s;
}
.p-button.button-sm .p-button-label {
  font-weight: 400;
}
.p-button.p-button-icon-only {
  width: 40px;
  height: 40px;
  padding: 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
}

/* button and icon sizes */
.p-button.button-xl {
  padding: 14px 17px;
  border-radius: 10px;
}
.p-button.button-icon {
  padding: 9px;
}
.p-button.button-icon-xl {
  padding: 6px;
}
.p-button.button-icon.button-xl {
  padding: 14px;
}
.p-button.button-icon-xl.button-xl {
  padding: 11px;
}

/* button tomato*/
.p-button.button-tomato {
  background: var(--tomato-main);
  border: 1px solid var(--tomato-main);
}
.p-button.button-tomato:enabled:hover,
.p-button.button-tomato:not(button):not(a):not(.p-disabled):hover {
  background: var(--tomato-dark);
  border-color: var(--tomato-dark);
}

/* button outlined */
.p-button.p-button-outlined {
  background-color: var(--primary-white);
  color: var(--heavy-blue-60);
  border-color: var(--heavy-blue-40);
}
.p-button.p-button-outlined.button-xl {
  color: var(--heavy-blue-80);
}
.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-button.p-button-outlined:enabled:active,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background-color: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
  border-color: var(--heavy-blue-100);
}
.p-button.p-button-outlined:focus {
  box-shadow: 0px 0px 0px 4px #676e7629;
}
/* button outlined blue*/
.p-button.p-button-outlined.button-blue {
  color: var(--blue-main);
  border-color: var(--blue-main);
}
.p-button.p-button-outlined.button-blue:enabled:hover,
.p-button.p-button-outlined.button-blue:not(button):not(a):not(.p-disabled):hover,
.p-button.p-button-outlined.button-blue:enabled:active,
.p-button.p-button-outlined.button-blue:not(button):not(a):not(.p-disabled):active {
  color: var(--blue-dark);
  border-color: var(--blue-dark);
}

/* button text */
.p-button.p-button-text {
  color: var(--heavy-blue-80);
  background-color: transparent;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-button.p-button-text:enabled:active,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-button.p-button-text:focus {
  color: var(--heavy-blue-100);
  background-color: var(--heavy-blue-1);
  border-color: var(--heavy-blue-1);
  box-shadow: none;
}
/* button text blue*/
.p-button.p-button-text.button-blue {
  color: var(--blue-main);
}
.p-button.p-button-text.button-blue:enabled:hover,
.p-button.p-button-text.button-blue:not(button):not(a):not(.p-disabled):hover,
.p-button.p-button-text.button-blue:enabled:active,
.p-button.p-button-text.button-blue:not(button):not(a):not(.p-disabled):active,
.p-button.p-button-text.button-blue:focus {
  color: var(--blue-dark);
  border-color: var(--blue-soft);
  background-color: var(--blue-soft);
}

/* button text line*/
.p-button.button-text-line {
  color: var(--heavy-blue-80);
  background-color: transparent;
  border-color: transparent;
  border-width: 0;
  padding: 0;
}
.p-button.button-text-line:enabled:hover,
.p-button.button-text-line:not(button):not(a):not(.p-disabled):hover,
.p-button.button-text-line:enabled:active,
.p-button.button-text-line:not(button):not(a):not(.p-disabled):active,
.p-button.button-text-line:focus {
  color: var(--heavy-blue-100);
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
/* button text line blue*/
.p-button.button-text-line.button-blue {
  color: var(--blue-main);
}
.p-button.button-text-line.button-blue:enabled:hover,
.p-button.button-text-line.button-blue:not(button):not(a):not(.p-disabled):hover,
.p-button.button-text-line.button-blue:enabled:active,
.p-button.button-text-line.button-blue:not(button):not(a):not(.p-disabled):active,
.p-button.button-text-line.button-blue:focus {
  color: var(--blue-dark);
  background-color: transparent;
  border-color: transparent;
}

/* button severity danger */
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button,
.p-fileupload-choose.p-button-danger {
  color: var(--tomato-main);
  background: var(--tomato-light);
  border: 1px solid var(--tomato-light);
}
.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:enabled:hover,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
  background: var(--tomato-light);
  color: var(--tomato-dark);
  border-color: var(--tomato-light);
}
.p-button.p-button-danger:enabled:focus,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:enabled:focus,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0px 0px 0px 4px var(--tomato-light);
}
.p-button.p-button-danger:enabled:active,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:enabled:active,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
  background: var(--tomato-light);
  color: var(--tomato-main);
  border-color: var(--tomato-light);
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
  background-color: transparent;
  color: var(--tomato-main);
  border-color: var(--tomato-main);
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background-color: var(--tomato-soft);
  color: var(--tomato-dark);
  border-color: var(--tomato-dark);
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: var(--tomato-soft);
  color: var(--tomato-dark);
  border-color: var(--tomato-soft);
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text {
  background-color: transparent;
  color: var(--tomato-main);
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background-color: transparent;
  color: var(--tomato-dark);
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
  background-color: transparent;
  color: var(--tomato-dark);
  border-color: transparent;
}

/* add button */
.p-button.button-add {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-50);
  border-radius: 4px;
  height: 16px;
  padding: 0;
  border: none;
}
.p-button.button-add:enabled:hover,
.p-button.button-add:not(button):not(a):not(.p-disabled):hover,
.p-button.button-add:enabled:active,
.p-button.button-add:not(button):not(a):not(.p-disabled):active,
.p-button.button-add:focus {
  background: var(--blue-light);
  color: var(--blue-main);
  box-shadow: none;
}
.p-button.p-button-text.button-secondary {
  color: var(--heavy-blue-50);
}
.p-button.p-button-text.button-secondary:enabled:hover,
.p-button.p-button-text.button-secondary:not(button):not(a):not(.p-disabled):hover,
.p-button.p-button-text.button-secondary:enabled:active,
.p-button.p-button-text.button-secondary:not(button):not(a):not(.p-disabled):active,
.p-button.p-button-text.button-secondary:focus {
  color: var(--heavy-blue-80);
}

/* button severity success */
.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button,
.p-fileupload-choose.p-button-success {
  color: var(--cyan-main);
  background: var(--cyan-light);
  border: 1px solid var(--cyan-light);
}
.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:enabled:hover,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
  background: var(--cyan-light);
  color: var(--cyan-dark);
  border-color: var(--cyan-light);
}
.p-button.p-button-success:enabled:focus,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:enabled:focus,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0px 0px 0px 4px var(--cyan-light);
}
.p-button.p-button-success:enabled:active,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:enabled:active,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
  background: var(--cyan-light);
  color: var(--cyan-main);
  border-color: var(--cyan-light);
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined {
  background-color: transparent;
  color: var(--cyan-main);
  border-color: var(--cyan-main);
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background-color: var(--cyan-soft);
  color: var(--cyan-dark);
  border-color: var(--cyan-dark);
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: var(--cyan-soft);
  color: var(--cyan-dark);
  border-color: var(--cyan-soft);
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text {
  background-color: transparent;
  color: var(--cyan-main);
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background-color: transparent;
  color: var(--cyan-dark);
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:enabled:active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
  background-color: transparent;
  color: var(--cyan-dark);
  border-color: transparent;
}

/* select button */
.p-selectbutton .p-button {
  background: var(--primary-white);
  border: 1px solid var(--heavy-blue-20);
  color: var(--heavy-blue-80);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: var(--heavy-blue-80);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--heavy-blue-1);
  border-color: var(--heavy-blue-20);
  color: var(--heavy-blue-100);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: var(--heavy-blue-80);
}
.p-selectbutton .p-button.p-highlight {
  background: var(--blue-main);
  border-color: var(--blue-main);
  color: var(--primary-white);
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: var(--primary-white);
}
.p-selectbutton .p-button.p-highlight:hover {
  background: var(--blue-dark);
  border-color: var(--blue-dark);
  color: var(--primary-white);
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: var(--primary-white);
}
.p-selectbutton.p-invalid > .p-button {
  border-color: var(--tomato-main);
}
.p-selectbutton .p-button {
  margin: 0;
}
.p-selectbutton .p-button:not(:last-child) {
  border-right: 0 none;
}
.p-selectbutton .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
.p-selectbutton .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-selectbutton .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-selectbutton .p-button:focus {
  position: relative;
  z-index: 1;
}

/* inputtext */
.p-inputtext {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005rem;
  color: var(--heavy-blue-100);
  background: var(--primary-white);
  padding: 11px;
  border: 1px solid var(--heavy-blue-40);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 8px;
}
.p-inputtext::placeholder {
  color: var(--heavy-blue-50);
}
.p-inputtext.inputtext-sm,
.p-dropdown.dropdown-sm .p-inputtext,
.p-autocomplete.autocomplete-sm .p-inputtext,
.p-calendar.calendar-sm .p-inputtext {
  padding: 7px 9px;
  border-radius: 6px;
  font-size: 13px;
}
.p-inputtext:enabled:hover {
  color: var(--heavy-blue-60);
  border-color: var(--heavy-blue-40);
}
.p-inputtext:enabled:focus {
  color: var(--heavy-blue-100);
  border-color: var(--blue-main);
  box-shadow: none;
  outline: 0 none;
  outline-offset: 0;
}
.p-inputtext.p-invalid.p-component {
  border-color: var(--tomato-main);
}

/* listbox */
.p-listbox {
  background: var(--primary-white);
}
.p-listbox .p-listbox-header {
  border-bottom: 1px solid var(--heavy-blue-20);
}
.p-listbox .p-listbox-header .p-listbox-filter {
  border: none;
  padding-left: 38px;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  left: 12px;
  color: var(--heavy-blue-60);
}
.p-listbox .p-listbox-list .p-listbox-item {
  padding: 12px;
  color: var(--heavy-blue-80);
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: var(--heavy-blue-80);
  background-color: var(--blue-soft);
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--shadow-focus);
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  color: var(--heavy-blue-80);
  background: var(--primary-white);
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 12px;
  color: var(--heavy-blue-80);
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--heavy-blue-80);
  background: var(--heavy-blue-1);
}
.p-listbox.p-invalid {
  background: var(--tomato-light);
}

/* dropdown */
.p-dropdown {
  background: var(--primary-white);
  color: var(--heavy-blue-100);
  border: 1px solid var(--heavy-blue-40);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-dropdown.dropdown-sm {
  border-radius: 6px;
}
.p-dropdown.dropdown-xl {
  border-radius: 10px;
}

.p-dropdown .p-dropdown-label.p-inputtext {
  border: none;
}
.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled):hover .p-dropdown-label {
  color: var(--heavy-blue-60);
  border-color: var(--heavy-blue-40);
}
.p-dropdown:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):focus .p-dropdown-label {
  color: var(--heavy-blue-100);
  border-color: var(--blue-main);
  box-shadow: none;
  /* box-shadow: var(--shadow-focus); */
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-trigger {
  color: var(--heavy-blue-40);
  width: 34px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: var(--heavy-blue-40);
  right: 34px;
}
.p-dropdown.p-invalid.p-component {
  border-color: var(--tomato-main);
}
.p-dropdown.no-trigger .p-dropdown-trigger {
  display: none;
}

/* dropdown panel*/
.p-dropdown-panel {
  background-color: var(--primary-white);
  color: var(--heavy-blue-80);
  border: 0 none;
  border-radius: 8px;
  box-shadow: var(--shadow-level-2);
  margin-top: 4px;
}
.p-dropdown-panel .p-dropdown-header {
  padding: 4px 0;
  margin: 0 4px;
  border-bottom: 1px solid var(--heavy-blue-10);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-left: 12px;
  padding-right: 32px;
  border: 0 none;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon,
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-clear-icon {
  position: absolute;
  margin: 0;
  top: 12px;
  right: 8px;
  color: var(--heavy-blue-60);
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
  padding-right: 48px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
  right: 32px;
  cursor: pointer;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 4px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  background: transparent;
  color: var(--heavy-blue-80);
  margin: 0;
  padding: 0 12px;
  border: 0 none;
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.005rem;
  height: 40px;
  display: flex;
  align-items: center;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--blue-soft);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-disabled):not(.p-disabled):hover {
  background-color: var(--heavy-blue-1) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0 12px;
  border: 0 none;
  background: transparent;
  color: var(--heavy-blue-80);
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.005rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 12px;
  color: var(--heavy-blue-80);
  background: transparent;
}

/* calendar */
.p-calendar {
  position: relative;
  display: inline-flex;
  max-width: 100%;
}
.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
}
.p-calendar .p-datepicker-trigger {
  width: 40px;
  padding: 0;
}
.p-calendar-w-btn-right .p-inputtext {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
}
.p-calendar-w-btn-right .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-calendar-w-btn-left .p-inputtext {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none;
}
.p-calendar-w-btn-left .p-datepicker-trigger {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-calendar.calendar-sm .p-datepicker-trigger {
  width: 32px;
}

/* checkbox */
.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid var(--heavy-blue-40);
  background: var(--primary-white);
  width: 20px;
  height: 20px;
  color: var(--heavy-blue-40);
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: var(--primary-white);
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--blue-main);
  background: var(--blue-main);
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--blue-dark);
  background: var(--blue-dark);
  color: var(--primary-white);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--heavy-blue-40);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--shadow-focus);
  border-color: var(--blue-main);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--blue-dark);
  background: var(--blue-dark);
  color: var(--primary-white);
}
.p-checkbox .p-checkbox-box.p-disabled:not(.p-highlight) {
  border-color: var(--heavy-blue-10);
  background: var(--heavy-blue-10);
  opacity: 1;
}
.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: var(--tomato-main);
}

/* radiobutton */
.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 1px solid var(--heavy-blue-50);
  background: var(--primary-white);
  width: 20px;
  height: 20px;
  /* color: #495057; */
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--blue-main);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--shadow-focus);
  border-color: var(--blue-main);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  transition-duration: 0.2s;
  background-color: var(--primary-white);
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--blue-main);
  background: var(--blue-main);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--blue-dark);
  background: var(--blue-dark);
  color: var(--primary-white);
}
.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: var(--tomato-main);
}
.p-radiobutton:focus {
  outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: var(--heavy-blue-1);
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: var(--heavy-blue-1);
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--blue-main);
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--blue-dark);
}

/* datepicker */
.p-datepicker:not(.p-datepicker-inline) {
  background: var(--primary-white);
  border: 0 none;
  border-radius: 8px;
  box-shadow: 0px 4px 22px 0px rgba(14, 36, 59, 0.1);
  transform: translate(10px, -4px);
}

/* avatar */
.p-avatar {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.01rem;
  background-color: var(--blue-light);
  color: var(--blue-dark);
  overflow: hidden;
}
.p-avatar.p-avatar-lg {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
}
.p-avatar.p-avatar-xl {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
}
.p-avatar.p-avatar-image {
  background-color: var(--blue-light);
}
.p-avatar img {
  object-fit: cover;
}

/* menu */
.p-menu {
  padding: 2px 0;
  background: var(--primary-white);
  color: var(--heavy-blue-80);
  border: 0 none;
  border-radius: 8px;
  --menu-width: max-content;
  width: var(--menu-width);
  /* this is very specific, it should not be in this file, it affects all the menus */
  /* max-width: 240px;
  min-width: 115px; */
}
.p-menu.p-menu-overlay {
  border: 0 none;
  box-shadow: var(--shadow-level-2);
  margin: 4px 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid var(--heavy-blue-20);
  margin: 6px 0;
}
.p-menu .p-menu-item {
  color: var(--heavy-blue-80);
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
}
.p-menu .p-menuitem-link {
  color: var(--heavy-blue-80);
  min-height: 40px;
  padding: 12px;
  border: 2px solid var(--primary-white);
  border-left-width: 4px;
  border-right-width: 4px;
  border-radius: 8px;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  color: var(--heavy-blue-100);
  background-color: var(--blue-soft);
}
.p-menu .p-menuitem-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.005rem;
}

/* inputgroup */
.p-inputgroup-addon {
  background: var(--primary-white);
  color: var(--heavy-blue-60);
  border-top: none;
  border-left: none;
  border-bottom: none;
  padding: 0;
  min-width: 40px;
}
.p-inputgroup-addon:last-child {
  border-right: none;
}
.p-inputgroup.inputgroup-sm .p-inputgroup-addon {
  min-width: 32px;
}
.p-inputgroup.inputgroup-xl .p-inputgroup-addon {
  min-width: 48px;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-inputgroup.inputgroup-sm .p-inputgroup-addon:first-child,
.p-inputgroup.inputgroup-sm button:first-child,
.p-inputgroup.inputgroup-sm input:first-child,
.p-inputgroup.inputgroup-sm > .p-inputwrapper:first-child,
.p-inputgroup.inputgroup-sm > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup.inputgroup-sm .p-float-label:first-child input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup.inputgroup-sm .p-inputgroup-addon:last-child,
.p-inputgroup.inputgroup-sm button:last-child,
.p-inputgroup.inputgroup-sm input:last-child,
.p-inputgroup.inputgroup-sm > .p-inputwrapper:last-child,
.p-inputgroup.inputgroup-sm > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-inputgroup.inputgroup-sm .p-float-label:last-child input {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputgroup.inputgroup-xl .p-inputgroup-addon:first-child,
.p-inputgroup.inputgroup-xl button:first-child,
.p-inputgroup.inputgroup-xl input:first-child,
.p-inputgroup.inputgroup-xl > .p-inputwrapper:first-child,
.p-inputgroup.inputgroup-xl > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-inputgroup.inputgroup-xl .p-float-label:first-child input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-inputgroup.inputgroup-xl .p-inputgroup-addon:last-child,
.p-inputgroup.inputgroup-xl button:last-child,
.p-inputgroup.inputgroup-xl input:last-child,
.p-inputgroup.inputgroup-xl > .p-inputwrapper:last-child,
.p-inputgroup.inputgroup-xl > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.p-inputgroup.inputgroup-xl .p-float-label:last-child input {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-tabview .p-tabview-nav {
  background: var(--primary-white);
  border: 1px solid var(--heavy-blue-20);
  border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
  color: var(--heavy-blue-60);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01rem;
  font-weight: 500;
  border: solid transparent;
  border-width: 0 0 2px 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: none;
  padding: 8px 20px;
  margin: 0 0 -1px 0;
  height: 40px;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  font-size: 14px;
  line-height: 16px;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tabview .p-tabview-nav li:not(.p-disabled):hover .p-tabview-nav-link {
  background: var(--heavy-blue-1);
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: var(--heavy-blue-1);
  border-color: transparent;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: var(--blue-main);
  color: var(--blue-main);
}
.p-tabview .p-tabview-panels {
  background: transparent;
  padding: 0;
  border: 0 none;
  color: initial;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-tabview.tabview-embed {
  display: flex;
  flex-direction: column;
}
.p-tabview.tabview-embed .p-tabview-panels {
  flex-grow: 1;
  height: 0;
}

/* multiselect */
.p-multiselect {
  color: var(--heavy-blue-100);
  border: 1px solid var(--heavy-blue-40);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-multiselect:not(.p-disabled):hover {
  color: var(--heavy-blue-60);
  border-color: var(--heavy-blue-40);
}
.p-multiselect:not(.p-disabled).p-focus {
  color: var(--heavy-blue-100);
  border-color: var(--blue-main);
  box-shadow: none;
  outline: 0 none;
  outline-offset: 0;
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 24px;
}
.p-multiselect .p-multiselect-label {
  padding: 12px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  /* color: var(--heavy-blue-50); */
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.005rem;
  padding: 12px;
}
.p-multiselect.p-multiselect-chip {
  border-radius: 8px;
  border-color: var(--heavy-blue-40);
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  height: 40px;
  padding: 8px;
  margin-right: 4px;
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.005rem;
  font-weight: 500;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 8px;
  color: var(--heavy-blue-50);
  font-size: 12px;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label,
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
  padding: 10px 12px;
}
.p-multiselect .p-multiselect-trigger {
  align-items: flex-start;
  padding: 12px 10px 0 0;
  color: var(--heavy-blue-40);
  background: transparent;
  width: 24px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-multiselect .p-multiselect-clear-icon {
  color: var(--heavy-blue-60);
  right: 24px;
}
.p-multiselect.p-invalid.p-component {
  border-color: var(--tomato-main);
}
.p-multiselect-panel {
  background: var(--primary-white);
  color: var(--heavy-blue-60);
  border: 0 none;
  border-radius: 8px;
  box-shadow: var(--shadow-level-2);
  margin-top: 4px;
}
.p-multiselect-panel .custom-multiselect-header {
  padding: 4px 4px 0;
}
.p-multiselect-panel .custom-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding: 12px 12px 12px 44px;
  border-width: 0;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
}
.p-multiselect-panel .custom-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  left: 12px;
  margin: 0;
  transform: translateY(-50%);
  color: var(--heavy-blue-60);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 10px 16px;
  border-bottom: 0 none;
  color: var(--heavy-blue-60);
  background: var(--primary-white);
  margin: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0 10px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 24px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 10px;
  color: var(--heavy-blue-60);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 20px;
  height: 20px;
  color: var(--heavy-blue-60);
  border: 0 none;
  background: transparent;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: var(--heavy-blue-100);
  border-color: transparent;
  background: var(--heavy-blue-1);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 4px;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 10px 12px;
  border: 0 none;
  color: var(--heavy-blue-80);
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item > span {
  width: 100%;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--heavy-blue-100);
  background: var(--primary-white);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--heavy-blue-100);
  background: var(--heavy-blue-1);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 12px;
  pointer-events: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 10px 12px;
  color: var(--heavy-blue-60);
  background: var(--primary-white);
  font-weight: 400;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 10px 12px;
  color: var(--heavy-blue-50);
  background: transparent;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.005rem;
}

/* inputnumber */
.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: var(--tomato-main);
}
.p-inputnumber .p-inputnumber-button.p-button-icon-only {
  height: auto;
}

/* inputswitch */
.p-inputswitch {
  width: 40px;
  height: 24px;
}
.p-inputswitch:is(.size-small) {
  width: 36px;
  height: 20px;
}
.p-inputswitch .p-inputswitch-slider {
  background: var(--heavy-blue-10);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: var(--primary-white);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition-duration: 0.2s;
  margin: -10px 2px 2px 2px;
  left: 0;
  box-shadow: 0px 0px 2px 0px #0000001a;
}
.p-inputswitch:is(.size-small) .p-inputswitch-slider:before {
  width: 16px;
  height: 16px;
  margin: -8px 2px 2px 2px;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before,
.p-inputswitch.p-highlight .p-inputswitch-slider:before,
.p-inputswitch:is(.size-small).p-inputswitch-checked .p-inputswitch-slider:before,
.p-inputswitch:is(.size-small).p-highlight .p-inputswitch-slider:before {
  transform: translateX(15px);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--shadow-focus);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #dce2ec;
}
.p-inputswitch:is(.p-disabled) .p-inputswitch-slider {
  background: #dce1ec;
}
.p-inputswitch:is(.p-disabled) .p-inputswitch-slider:before {
  background: #f6f7fa;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-highlight .p-inputswitch-slider {
  background: var(--blue-main);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before,
.p-inputswitch.p-highlight .p-inputswitch-slider:before {
  background: var(--primary-white);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider,
.p-inputswitch.p-highlight:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--blue-dark);
}
.p-inputswitch.p-inputswitch-checked:is(.p-disabled) .p-inputswitch-slider,
.p-inputswitch.p-highlight:is(.p-disabled) .p-inputswitch-slider {
  opacity: 50%;
}
.p-inputswitch.p-inputswitch-checked:is(.p-disabled) .p-inputswitch-slider:before,
.p-inputswitch.p-highlight:is(.p-disabled) .p-inputswitch-slider:before {
  background: var(--blue-light);
}

/* dialog */
.p-dialog {
  border-radius: 8px;
  border: 0 none;
  box-shadow: var(--shadow-level-3);
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
}
.p-dialog .p-dialog-header {
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid var(--heavy-blue-20);
  background: var(--primary-white);
  color: var(--heavy-blue-100);
  padding: 20px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 20px;
  height: 20px;
  color: var(--heavy-blue-60);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: var(--heavy-blue-100);
  border-color: transparent;
  background: var(--primary-white);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 4px var(--heavy-blue-10);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: var(--primary-white);
  color: var(--heavy-blue-100);
  padding: 24px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid var(--heavy-blue-20);
  background: var(--primary-white);
  color: var(--heavy-blue-80);
  padding: 12px 20px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.p-dialog .p-dialog-footer button {
  margin: 0;
  padding: 14px 17px;
  border-radius: 10px;
}

/* overlaypanel */
.p-overlaypanel {
  background: var(--primary-white);
  border: 0 none;
  border-radius: 8px;
  box-shadow: var(--shadow-level-3);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 16px;
}
.p-overlaypanel .p-overlaypanel-close {
  background: var(--blue-main);
  color: var(--primary-white);
  width: 20px;
  height: 20px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: var(--blue-main);
  color: var(--primary-white);
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: var(--primary-white);
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: var(--heavy-blue-1);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: var(--primary-white);
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: var(--primary-white);
}

/* sidebar */
.p-sidebar {
  background: var(--primary-white);
  color: var(--heavy-blue-100);
  border: 0 none;
  box-shadow: var(--shadow-level-3);
}
.p-sidebar .p-sidebar-header {
  padding: 20px;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 20px;
  height: 20px;
  color: var(--heavy-blue-60);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: var(--heavy-blue-100);
  border-color: transparent;
  background: var(--primary-white);
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 4px var(--heavy-blue-10);
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 24px 20px;
}

/* divider */
.p-divider.p-divider-horizontal:before {
  border-top: 1px solid var(--heavy-blue-20);
}
.p-divider.p-divider-vertical:before {
  border-left: 1px solid var(--heavy-blue-20);
}

/* datepicker */
.p-datepicker {
  padding: 4px;
  background: var(--primary-white);
  color: #4b5563;
  border: 0 none;
  border-radius: 8px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: var(--primary-white);
  border: 0 none;
  box-shadow: 0px 4px 22px 0px #0e243b1a;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: var(--primary-white);
}
.p-datepicker .p-datepicker-header {
  padding: 4px;
  color: #4b5563;
  background: var(--primary-white);
  font-weight: 600;
  margin: 0;
  border-bottom: 0 none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #4b5563;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #a6d5fa;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #4b5563;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 600;
  padding: 0.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #2196f3;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 14px;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0;
}
.p-datepicker table th > span {
  width: 42px;
  height: 42px;
}
.p-datepicker table td {
  padding: 4px;
}
.p-datepicker table td > span {
  width: 42px;
  height: 42px;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #4b5563;
  border-color: var(--blue-main);
  background: var(--primary-white);
}
.p-datepicker table td > span.p-highlight:hover {
  background: var(--heavy-blue-1);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  color: var(--blue-main);
  border-color: var(--blue-main);
  background: var(--blue-soft);
}
.p-datepicker table td.p-datepicker-today > span {
  background: #d2d5db;
  color: #4b5563;
  border-color: #d2d5db;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  background: #d2d5db;
  border-color: #d2d5db;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #4b5563;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #a6d5fa;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #4b5563;
  background: #e3f2fd;
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #4b5563;
  background: #e3f2fd;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-left: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--heavy-blue-1);
  border-color: var(--heavy-blue-1);
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  color: var(--blue-main);
  border-color: var(--blue-main);
  background: var(--blue-soft);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #a6d5fa;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #a6d5fa;
}

/* scrollpanel */
.p-scrollpanel {
  width: 100%;
  height: 100%;
}
.p-scrollpanel .p-scrollpanel-content {
  height: 100%;
  width: 100%;
  padding: 0;
  scrollbar-width: none;
}
.p-scrollpanel .p-scrollpanel-content::-webkit-scrollbar {
  width: 0px;
}
.p-scrollpanel .p-scrollpanel-content > div {
  max-width: 100vw;
}
.p-scrollpanel .p-scrollpanel-bar {
  position: relative;
  background: var(--heavy-blue-10);
  border-radius: 4px;
}
.p-scrollpanel .p-scrollpanel-bar-y {
  width: 8px;
  transform: translateX(-4px);
}
.p-scrollpanel .p-scrollpanel-bar-x {
  height: 8px;
}

/* datatable */
/* .p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 14px 14px 0 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0 0 14px 14px;
} */
.p-datatable .p-datatable-header {
  background: var(--primary-white);
  color: var(--heavy-blue-60);
  border: 1px solid var(--heavy-blue-10);
  border-width: 0 0 1px 0;
  padding: 8px 16px;
  font-weight: 400;
}
.p-datatable .p-datatable-footer {
  background: var(--primary-white);
  color: var(--heavy-blue-60);
  border: none;
  border-width: 0;
  padding: 8px 16px;
  font-weight: 400;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 8px;
  border: 1px solid var(--heavy-blue-10);
  border-width: 0 0 1px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01rem;
  color: var(--heavy-blue-60);
  background: var(--primary-white);
}
.p-datatable .p-datatable-thead > tr > th:first-child {
  padding-left: 16px;
}
.p-datatable .p-datatable-thead > tr > th:last-child {
  padding-right: 16px;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 8px;
  border: 1px solid var(--heavy-blue-10);
  border-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01rem;
  color: var(--heavy-blue-60);
  background: var(--primary-white);
}
.p-datatable .p-datatable-tfoot > tr > td:first-child {
  padding-left: 16px;
}
.p-datatable .p-datatable-tfoot > tr > td:last-child {
  padding-right: 16px;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--heavy-blue-100);
  margin-left: 4px;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 16px;
  min-width: 16px;
  line-height: 16px;
  color: var(--heavy-blue-80);
  background: var(--heavy-blue-1);
  margin-left: 4px;
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: var(--heavy-blue-100);
}
.p-datatable .p-sortable-column.p-highlight {
  background: var(--primary-white);
  color: var(--heavy-blue-100);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--heavy-blue-100);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: var(--heavy-blue-100);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: var(--primary-white);
  color: var(--heavy-blue-100);
  transition: box-shadow 0.2s;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--heavy-blue-10);
  border-width: 0 0 1px 0;
  padding: 5px 8px;
}
.p-datatable .p-datatable-tbody > tr > td:first-child {
  padding-left: 16px;
}
.p-datatable .p-datatable-tbody > tr > td:last-child {
  padding-right: 16px;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 32px;
  height: 32px;
  color: var(--heavy-blue-60);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: var(--heavy-blue-100);
  border-color: transparent;
  background: var(--heavy-blue-1);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 4px var(--blue-soft);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 4px;
}
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  font-weight: 400;
}
.p-datatable .p-datatable-tbody > tr > td.p-highlight {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--blue-soft);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--blue-soft);
}
.p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
}
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
  outline: 2px solid var(--blue-soft);
  outline-offset: -2px;
}
.p-datatable.p-datatable-selectable-cell
  .p-datatable-tbody
  > tr.p-selectable-row
  > td.p-selectable-cell:not(.p-highlight):hover {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
}
.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus {
  outline: 2px solid var(--blue-soft);
  outline-offset: -2px;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-100);
}
.p-datatable .p-column-resizer-helper {
  background: var(--blue-main);
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: var(--heavy-blue-1);
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-virtualscroller
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-virtualscroller
  > .p-datatable-table
  > .p-datatable-tfoot {
  background-color: var(--heavy-blue-1);
}
.p-datatable .p-datatable-loading-icon {
  font-size: 20px;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody)
  .p-datatable-tbody
  > tr
  > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot)
  .p-datatable-tbody
  > tr:last-child
  > td {
  border-width: 0 0 0 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot)
  .p-datatable-tbody
  > tr:last-child
  > td:last-child {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: var(--blue-soft);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: #e3f2fd;
  color: var(--heavy-blue-100);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
  color: var(--heavy-blue-60);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
  color: var(--heavy-blue-60);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
  background: var(--heavy-blue-1);
}
/* .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--heavy-blue-1);
  border-width: 0 0 1px 0;
  padding: 6px 8px 5px;
}
.p-datatable .p-datatable-tbody > tr > td:first-child {
  padding-left: 16px;
}
.p-datatable .p-datatable-tbody > tr > td:last-child {
  padding-right: 16px;
} */
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 8px;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 8px;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 1px 8px 1px;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 8px;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 8px;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th:first-child,
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td:first-child,
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td:first-child {
  padding-left: 20px;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th:last-child,
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td:last-child,
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td:last-child {
  padding-right: 20px;
}
.p-datatable.datatable-no-lines .p-datatable-header,
.p-datatable.datatable-no-lines .p-datatable-thead > tr > th,
.p-datatable.datatable-no-lines .p-datatable-tbody > tr > td {
  border-width: 0;
}
.p-datatable-drag-selection-helper {
  background: rgba(33, 150, 243, 0.16);
}
.p-selection-column .p-checkbox {
  vertical-align: middle;
}

/* accordion */
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
  border: none;
  color: var(--heavy-blue-60);
  background: transparent;
  font-weight: 400;
  border-radius: 8px;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-header-text {
  width: 100%;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-60);
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: transparent;
  color: var(--heavy-blue-60);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-accordion:not(.accordion-separate-header) .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-60);
}
.p-accordion .p-accordion-content {
  padding: 0;
  border: none;
  background: transparent;
  color: var(--heavy-blue-100);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link,
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* tooltip */
.p-tooltip .p-tooltip-text {
  background: var(--primary-white);
  color: var(--heavy-blue-100);
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.005rem;
  padding: 8px 12px;
  box-shadow: var(--shadow-level-3);
  border-radius: 4px;
}
.p-tooltip .p-tooltip-arrow {
  display: none;
}

/* spinner */
.p-progress-spinner-circle {
  stroke: var(--heavy-blue-40);
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
}

/* picklist */
.p-picklist .p-picklist-buttons {
  padding: 0 8px;
  gap: 8px;
}
.p-picklist .p-picklist-buttons .p-button {
  /* margin-bottom: 0.5rem; */
}
.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: var(--heavy-blue-80);
  border: 1px solid #dee2e6;
  padding: 1rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.5rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-picklist .p-picklist-list-wrapper {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden;
}
.p-picklist .p-picklist-list {
  border: none;
  background: #ffffff;
  color: var(--heavy-blue-80);
}
.p-picklist.p-invalid .p-picklist-target-wrapper .p-picklist-list {
  border-color: var(--tomato-main);
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: var(--heavy-blue-80);
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: var(--heavy-blue-1);
  color: var(--heavy-blue-80);
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--shadow-focus);
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: var(--heavy-blue-80);
  background: var(--blue-light);
}

/* icon field */
.p-icon-field-left > .p-inputtext {
  padding-left: 38px;
}
.p-icon-field-left.p-float-label > label {
  left: 38px;
}
.p-icon-field-right > .p-inputtext {
  padding-right: 38px;
}
.p-icon-field {
  position: relative;
}
.p-icon-field > .p-input-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  stroke-width: 2.25;
  top: 50%;
  margin-top: -8px;
  color: var(--heavy-blue-50);
}
.p-fluid .p-icon-field-left,
.p-fluid .p-icon-field-right {
  width: 100%;
}
.p-icon-field-left > .p-input-icon:first-of-type {
  left: 12px;
}
.p-icon-field-right > .p-input-icon:last-of-type {
  right: 12px;
}

/* autocomplete */
.p-autocomplete {
  display: inline-flex;
  position: relative;
}
.p-autocomplete-loader {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}
.p-autocomplete .p-autocomplete-input {
  flex: 1 1 auto;
  width: 1%;
}
.p-autocomplete .p-autocomplete-input,
.p-autocomplete .p-autocomplete-multiple-container {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
}
.p-autocomplete .p-autocomplete-dropdown {
  width: 40px;
  padding: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-autocomplete.autocomplete-sm .p-autocomplete-dropdown {
  width: 32px;
}

.p-skeleton {
  background-color: var(--heavy-blue-10);
  border-radius: 4px;
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

/* toast */
.p-toast {
  max-width: 360px;
}
.p-toast.p-toast-top-right {
  --toast-indent: 28px;
  top: 60px;
  right: 14px;
}
.p-toast .p-toast-message {
  margin: 0 0 8px 0;
  box-shadow: var(--shadow-level-2);
  background: var(--primary-white);
  border: 1px solid var(--heavy-blue-20);
  border-radius: 8px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 14px;
  gap: 12px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  flex: none;
  width: 24px;
  height: 24px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01rem;
  color: var(--heavy-blue-80);
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  color: var(--heavy-blue-60);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  color: var(--heavy-blue-80);
  background-color: var(--heavy-blue-1);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--shadow-focus);
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: var(--cyan-main);
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
  color: var(--tomato-main);
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon {
  color: var(--heavy-blue-60);
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon {
  color: var(--saffron-main);
}
