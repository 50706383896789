@media screen and (max-width: 768px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }

  /* authentification */
  .auth-container {
    padding: 24px;
  }
  .auth-footer-container {
    padding: 12px 20px;
  }
  .auth-logo-container {
    padding: 0;
  }
  .auth-logo {
    height: 25px;
  }
  .auth-dropdown {
    margin: -10px 0;
  }
  .auth-spacer-1 {
    flex: 5 5;
  }
  .auth-spacer-2 {
    flex: 9 9;
  }
  .auth-title-container {
    padding: 0;
  }
  .auth-title {
    font-size: 28px;
    line-height: 32px;
  }
  .auth-buttons-container {
    width: 100%;
    padding-top: 32px;
  }
  .auth-trial-message {
    height: 0px;
    width: 100%;
  }
  .auth-options-container {
    gap: 12px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
  }
  .auth-options-link {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.005em;
  }

  /* booking page */
  .booking-background {
    display: none !important;
  }
  .booking-card {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
  .booking-header {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 16px 0 18px 20px;
    border-width: 0 0 1px;
    border-radius: 0;
  }
  .booking-title {
    padding: 0;
    font-size: 22px;
    line-height: 33px;
  }
  .booking-logo {
    margin-bottom: 20px;
  }
  .booking-content {
    flex-grow: 1;
    flex-direction: column;
    height: auto;
    overflow: auto;
  }
  .booking-content .footer-space {
    display: block;
    padding-top: 88px;
    border-top: 1px solid var(--heavy-blue-20);
  }
  .booking-details {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid var(--heavy-blue-20);
  }
  .booking-details > .booking-hosts {
    display: none;
  }
  .booking-details .details-button {
    display: flex;
  }
  .booking-details .booking-buttons,
  .locations-content .locations-buttons {
    border-top: 1px solid var(--heavy-blue-20);
    background-color: var(--primary-white);
    width: 100%;
    height: 88px;
    position: fixed;
    gap: 10px;
    bottom: 0;
    left: 0;
    padding: 19px 20px 20px;
    z-index: 3;
  }
  /* .booking-buttons > .p-button {
    width: auto !important;
  } */
  .booking-buttons > .p-button > span {
    display: none;
  }
  .booking-buttons > .button-book,
  .booking-buttons > .button-cancel {
    width: auto;
    flex-grow: 1;
  }
  .booking-time-container {
    flex-direction: column;
  }
  .booking-calendar {
    width: 100%;
    border-bottom: 1px solid var(--heavy-blue-20);
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--primary-white);
  }
  .booking-slots-container {
    border-radius: 0;
  }
  .booking-slots {
    gap: 10px;
    padding: 16px 0;
  }
  .time-slot-selector {
    color: var(--heavy-blue-60);
    background: var(--heavy-blue-1);
    border-color: var(--heavy-blue-20);
    width: 132px;
    height: 48px;
    padding: 0;
    border-radius: 8px;
  }
  .time-slot-selector.selected {
    background: var(--blue-soft);
  }
  /* .booking-info-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  } */
  .booking-info {
    max-width: 100%;
    padding: 20px;
  }
  .booked-content {
    padding: 8px 0 0;
  }
  .booked-content .booked-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .booked-content .booked-message {
    display: none;
  }
  .booked-content .booked-guests {
    padding: 20px;
  }
  .booked-content .booked-guests ~ .booked-actions .booked-links {
    display: none;
  }
  .booked-content .booked-actions {
    padding: 0;
    flex-grow: 1;
  }
  .booked-content .booking-details {
    padding: 20px;
    border-bottom: none;
  }
  .booked-content .booked-buttons {
    display: none;
    background-color: var(--primary-white);
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .booked-content .booked-buttons.show-buttons {
    display: block;
  }
  .booked-content .booked-buttons > .buttons-header {
    display: flex;
  }
  .booked-content .booked-buttons > .buttons-list {
    padding: 20px;
    gap: 16px;
  }
  .booked-content .booked-links {
    border-top: 1px solid var(--heavy-blue-20);
    background-color: var(--heavy-blue-1);
    padding: 20px;
    gap: 16px;
    height: -webkit-fill-available;
  }
  .booked-content .booked-manage-space {
    display: block;
  }
  .booked-content .booked-manage {
    display: block;
  }
  .booked-content .footer-space {
    display: block;
    padding-top: 158px;
    border-top: 1px solid var(--heavy-blue-20);
  }
  .booking-footer {
    display: none;
  }

  /* potential hosts step */
  .potential-hosts-container {
    padding: 20px;
  }
  .potential-hosts-buttons {
    padding: 12px 20px;
  }

  /* locations step */
  .locations-content .location-search-container {
    padding: 20px 20px 0;
  }
  .locations-content .location-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
  .locations-content .location-map {
    height: 350px;
    flex: none;
  }
  .locations-content .location-cards-container {
    padding: 32px 20px 20px;
  }
  .locations-content .footer-space {
    display: block;
    padding-top: 88px;
    border-top: 1px solid var(--heavy-blue-20);
  }
  .location-card-no-map {
    width: calc(50% - 8px);
  }

  .cookie-consent-modal {
    height: 400px;
  }
  .cookie-consent-modal .cookie-consent-body {
    flex-direction: column;
  }
}
